import { Component, OnInit, Input, ViewEncapsulation, ViewChild, ElementRef, TemplateRef, Output,  SimpleChanges, OnChanges, EventEmitter } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoordinatesService, TransformationType, Direction} from 'angular-coordinates';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MatDialog, MatDialogConfig, MatTabChangeEvent, MatRadioChange, MatDialogRef, MatSidenav } from '@angular/material';

import { UserService } from '../../../../../../services/user.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { WksCentralService } from '../../../../services/wks-central.service';

import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';
import { WksEntityModel } from '../../../../models/wks-entity.model';
import { EquipItemModel, RevisionModel } from '../../../../models/wks-equipment.model';
import { ParamActionsrefModel, ParamWorksRatesModel, TasksActionsModel, TaskrefJsonModel,
  WksTasksModel, ParentTask, TasksActionsContentModel, IhmOptions, TaskSN, WorkStatutModel } from '../../../../models/wks-param.model';
import { DataUpstream, TypesLabel} from '../../../../models/wks-common.model';

import { MessageDisplay } from '../../../../../../models/common.model';
import { LabelValue } from '../../../../../../models/data.model';
import { WksWorksModel } from '../../../../models/wks-works.model';

@Component({
  selector: 'mdi-works-tasks',
  templateUrl: './works-tasks.component.html',
  styleUrls: ['./works-tasks.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WorksTasksComponent implements OnInit {

  @Input() statutCall: any;
  @Input() actionType: string;
  @Input() equipItem: EquipItemModel;
  @Input() workTasksContent: WksTasksModel[];
  @Input() ihmOptions: IhmOptions;
  @Input() workStatut: WorkStatutModel;
  @Input() wksWorks: WksWorksModel;
  @Output() dataOut = new EventEmitter<any>();
  @Output() dataValidTasks = new EventEmitter<any>();
  private readonly onDestroy = new Subject<void>();

  @ViewChild('filterActions', {static: false}) filterActions: ElementRef;
  @ViewChild('filterTasks', {static: false}) filterTasks: ElementRef;
  @ViewChild('taskReferencedModal', {read: TemplateRef, static: false}) taskReferencedModal: TemplateRef<any>;

  @ViewChild('sidenavRight', {static: false}) sidenavRight: MatSidenav;

 
  rowTask: number;
  allAvailable: boolean;
  isReadOnly: boolean;
  cacheActionsList: TasksActionsModel[];
  parentTask: ParentTask;
  parentTaskRef: ParentTask;
  openedAccordion: boolean;
  tasksActionsContent: TasksActionsContentModel;
  tasksActionsParentContent: TasksActionsContentModel;

  wksEntity: WksEntityModel;

  listActionsCur: ParamActionsrefModel[];
  listTasksCur: WksTasksModel[];
  tasksReferenced: WksTasksModel[];
  cacheTasksList: WksTasksModel[];
  workCodeList: LabelValue[];
  actionsReferenced: TasksActionsModel[];
  actionsSelected: TasksActionsModel[];

  dataUpstream: DataUpstream;
  priceOption: string;
  modalRef: BsModalRef;
  
  configModal = {
    class: 'modal-dialog-centered modalTask',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: false,
    
  };
  messageDialogRef: MatDialogRef<ModalCommonComponent>;
 /*
  configModal = {
    class: 'modal-dialog-centered',
    backdrop: true,
    ignoreBackdropClick: true,
    animated: false,
    
  }*/
  allEquipmentsCheck: boolean;
  equipList: LabelValue[];
  equipListCheck: string[];
  openedRight: boolean;
  waitPricing: boolean[];

  // start new version code
  okToDisplay: boolean;
  rowEquip: {
    detailCollapse: boolean;
    equipId: string;
    equipSN: string;
    equipType: string;
    equipBrand: string;
    equipModel: string;
    revisions: RevisionModel[];
    isEven: boolean;
    isOdd: boolean;
    selected: boolean;
    workTasksEquip: WksTasksModel[];
    waitPricing: boolean[];
  }[];
  equipIdx: number;
  taskRowDisplay: number;
  rightSidenavCollapsed: boolean;
  verifyParts: boolean;
  workTasksContentInput: WksTasksModel[];
  // end new version code
  constructor(
    private _userService: UserService, 
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private modalService: BsModalService,
    private _dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.initData();
  }

  initData() {
    this.actionsSelected = [];
  
    this.initAllBooleans();
    this.initAllParam();
    this.initAllSubscribe();
    this.actionsSelected = [];
    this.wksEntity = this._wksCentralService.getWksEntityCur();
    this.equipList = this.equipItem.equipList;
    this.equipList.sort((obj1, obj2) => {
      return obj1.label > obj2.label ? 1 : -1;
    });
    this.workTasksContentInput = JSON.parse(JSON.stringify(this.workTasksContent));
    this.verifPrincing();

    this.initRowEquip();
  }
  initAllBooleans() {
    this.openedAccordion = false;

    this.allAvailable = false;
    this.isReadOnly = this.ihmOptions.readOnly;
    this.allEquipmentsCheck = true;
    this.waitPricing = [];
    this.okToDisplay = false;
    this.rightSidenavCollapsed = true;
    this.verifyParts = false;
  }

  initAllParam() {
    if (this.workStatut.label === 'accepted' || this.workStatut.label === 'approved') {
      this.verifyParts = true;
    }
    this.priceOption = (this._wksCentralService.getWksEntityCur().isQuotationActive ? 'withprice' : 'noprice');
    this.loadParamActionsRefByType(this.equipItem.equipType)
    .then ((responseType1: any) => {
      /*
      this.loadParamTasksRefByType(this.equipItem.equipType, this.equipItem.equipBrand, this.equipItem.equipModel)
      .then ((responseType2: any) => {
        

      })
      .catch ((error: any) => {

        console.log('loadParamTasksRefByType : ' + error);
        return;
      });
      */
    })
    .catch ((error: any) => {
      console.log('loadParamActionsRefByType : ' + error);
      /*
      this.loadParamTasksRefByType(this.equipItem.equipType, this.equipItem.equipBrand , this.equipItem.equipModel)
      .then ((responseType: any) => {
        
      })
      .catch ((error1: any) => {
        console.log('loadParamTasksRefByType : ' + error1);
        return;
      });
      */
      return;
    });
    this.loadWorksType()
    .then ((responseWork: any) => {

    })
    .catch ((error: any) => {
      
      console.log('loadWorksType : ' + error);
      return;
    }); // end works type

  }
  initAllSubscribe() {

  this._wksCentralService.taskWksEvent
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
      if (event.todo === 'changeValuesWorks') {
        this.setContentTask(event.orig, event.content as TasksActionsContentModel);
      } else if (event.todo === 'newTaskRef') {
        const newTask = event.content as WksTasksModel;
        this.tasksReferenced.push(newTask);
        this.affectTooltip(this.tasksReferenced);
        if ( this.parentTaskRef !== undefined &&  this.parentTaskRef.statutCall === 'modal') {
          this.closeModal();
        }
      } else if (event.todo === 'closeTaskRef') {
        if ( this.parentTaskRef !== undefined &&  this.parentTaskRef.statutCall === 'modal') {
          this.closeModal();
        }
      } else if (event) {
        const typeEvent = typeof event;
        const eventString = String(event);
      }
    });

  }
// work ref 
loadWorksType() {
    let dataReturn: ParamWorksRatesModel[];
    this.workCodeList = [];
    return new Promise((resolve, reject) => {
      this._wksCentralService.getWksWorkRatesList(this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
          dataReturn = data.body;
          for (const workCurTmp of dataReturn) {
            const corkCurItem: LabelValue = {
              label: workCurTmp.workLabel,
              value: workCurTmp.workCode
            };
            this.workCodeList.push(corkCurItem);
          }
          this.workCodeList.sort((obj1: LabelValue, obj2: LabelValue) => {
            return obj1.label > obj2.label ? 1 : -1;
          });
          this.workCodeList.splice(0, 0, {
              label: '',
              value: ''
            });
          resolve(dataReturn) ;
        }, err => {
          console.log(err);
          reject(err.status);
        }
      );
    });
  }
  onChangeWorkAction($event: any) {
    this.actionsReferenced = [];
    this.cacheActionsList = [];
    if (this.listActionsCur === undefined) {
      return;
    }
    if ($event !== '') {
      for (const actionReading of this.listActionsCur)  {
        if (actionReading.workCode === $event) {
          this.actionsReferenced.push({
            brandRef: actionReading.brandRef,
            actionrefLabel: actionReading.actionrefLabel,
            billingUnit: this._translate.getTranslate(actionReading.billingUnit.trim()),
            selected: false,
          });
          this.cacheActionsList.push({
            brandRef: actionReading.brandRef,
            actionrefLabel: actionReading.actionrefLabel,
            billingUnit: this._translate.getTranslate(actionReading.billingUnit.trim()),
            selected: false,
          });
        }
      }
      if (this.actionsReferenced.length > 0) {
        this.actionsReferenced.sort(function(obj1, obj2) {
          return obj1.actionrefLabel > obj2.actionrefLabel ? 1 : -1;
        });
        if (this.actionsSelected.length > 0 ) {      
          for (const actionSel of this.actionsSelected) {
            let numRow = 0;
            for ( const actionRef of this.actionsReferenced) {
              if (actionRef.actionrefLabel === actionSel.actionrefLabel) {
                this.actionsReferenced.splice(numRow, 1);
                break;
              }
              numRow++;
            }
          }
        }

      } 
    } else {
      this.initActionsReferencedList();
    }

  }
  initActionsReferencedList() {
    this.actionsReferenced = [];
    this.cacheActionsList = [];
    for (const actionReading of this.listActionsCur)  {
      this.actionsReferenced.push({
        brandRef: actionReading.brandRef,
        actionrefLabel: actionReading.actionrefLabel,
        billingUnit: this._translate.getTranslate(actionReading.billingUnit.trim()),
        selected: false,
      });
      this.cacheActionsList.push({
        brandRef: actionReading.brandRef,
        actionrefLabel: actionReading.actionrefLabel,
        billingUnit: this._translate.getTranslate(actionReading.billingUnit.trim()),
        selected: false,
      });

    }
    if (this.actionsReferenced.length > 0) {
      this.actionsReferenced.sort(function(obj1, obj2) {
        return obj1.actionrefLabel > obj2.actionrefLabel ? 1 : -1;
      });

    } else {
      // this.toReferenced = true;
      
    }
    if ((this.actionsSelected === undefined) || (this.actionsSelected.length === 0)) {
      // this.toSelected = true;
    } else {
      this.actionsSelected.sort(function(obj1, obj2) {
        return obj1.ranking > obj2.ranking ? 1 : -1;
      });
    }
  }
  onKeyFilterActions($event: any) {
    // console.log(value);
    if ( ($event === '' ) || ($event.target.value === '' )) {
      this.actionsReferenced = [...this.cacheActionsList];
      return;
    }
    let valueFinal: any;
    if ($event === '') {
      valueFinal = '';
    } else  {
      valueFinal = $event.target.value;
      if ( $event.keyCode === 32) {
        valueFinal += ' ';
      }
    }
    this.actionsReferenced = this.searchActions(valueFinal);
    this.filterActions.nativeElement.value = valueFinal;
  }
  searchActions(value: string) { 
    const filter = value.toLowerCase();
    return this.actionsReferenced.filter(actionCur => actionCur.actionrefLabel.toLowerCase().includes(filter));
  }
  // actions ref
  loadParamActionsRefByType(_equipType: string) {
    return new Promise((resolve, reject) => {
      this._wksCentralService.getParamActionsRefByTypeList(this._userService.getUserLogged().entity, 
                                                _equipType, this._userService.getUserLogged().userlang)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const actionsRead = data.body as ParamActionsrefModel[];
          this.listActionsCur = [...actionsRead];
          this.initActionsReferencedList();
          resolve(actionsRead);
        },
        err => {
          console.log(err);
          reject(err.status);
        }
      );
    });
  }
  onChangeWorkTask($event: any) {
    this.tasksReferenced = [];
    this.cacheTasksList = [];
    if (this.listTasksCur === undefined) {
      return;
    }
    if ($event !== '') {
      for (const taskReading of this.listTasksCur)  {
        if (taskReading.workCode === $event) {
          taskReading.selectedTask = false;
          this.tasksReferenced.push(taskReading);
          this.affectTooltip(this.tasksReferenced);
          this.cacheTasksList.push(taskReading);
        }
      }
      if (this.tasksReferenced.length > 0) {
        this.tasksReferenced.sort(function(obj1, obj2) {
          return obj1.taskrefLabel > obj2.taskrefLabel ? 1 : -1;
        });
        if ((this.workTasksContentInput !== undefined) && (this.workTasksContentInput.length > 0 )) {      
          for (const taskSel of this.workTasksContentInput) {
            let numRow = 0;
            for ( const taskRef of this.tasksReferenced) {
              if (taskRef.taskrefId === taskSel.taskrefOrig) {
                // this.tasksReferenced.splice(numRow, 1);
                taskRef.selectedTask = true;
                break;
              }
              numRow++;
            }
          }
        }

      } 
    } else {
      this.tasksReferenced = [...this.listTasksCur];
      this.cacheTasksList = [...this.listTasksCur];
      this.affectTooltip(this.tasksReferenced);
    }
    if (this.verifPrincing()) {
  
    }
  }
// ===================================== JLG - 2023-12-11

  readParamTasksRefByType(_equipType: string, _equipBrand: string, _equipModel: string, _equipSN: string) {
    return new Promise((resolve, reject) => {
     
      this._wksCentralService.getParamTasksrefByTypeList(this._userService.getUserLogged().entity, 
                        _equipType, this._userService.getUserLogged().userlang, _equipBrand, _equipModel)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const tasksRead = data.body;
          resolve(tasksRead);
      },
      err => {
        console.log(err);
        reject(err.status);
        }
      );
    });
  }
  readParamTasksRefByRevision( _taskList: string) {

    return new Promise((resolve, reject) => {
     
      this._wksCentralService.getTaskRefList(this._userService.getUserLogged().entity,  this._userService.getUserLogged().userlang, _taskList)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        data => {
          const tasksRead = data.body;
          resolve(tasksRead);
      },
      err => {
        console.log(err);
        reject(err.status);
        }
      );
    });
  }
  loadParamTasksRefByType(_equipType: string, _equipBrand: string, _equipModel: string, _equipSN: string, _taskList: string) {
    this.tasksReferenced = [];
    this.cacheTasksList = [];
    this.listTasksCur = [];
    this.readParamTasksRefByType(_equipType, _equipBrand, _equipModel, _equipSN)
    .then ((tasksRead1: WksTasksModel[]) => {
      this.listTasksCur = this.excludeManufacturerAttNotRevisions(tasksRead1);
      if (_equipSN !== undefined && _equipSN !== null && _equipSN !== '') {
        this.listTasksCur = [];
        this.listTasksCur = this.getRestrictedList(tasksRead1, _equipSN);
      }

      if (_taskList !== undefined) {
        this.readParamTasksRefByRevision(_taskList)
        .then ((tasksRead2: WksTasksModel[]) => {
          // this.listTasksCur = [...tasksRead2];
          this.listTasksCur = this.listTasksCur.concat(tasksRead2);
          this.generateLisTasks();
        })
        .catch ((error: any) => {
          console.log('readParamTasksRefByRevision : ' + error);
          this.generateLisTasks();
        });
      } else {
        this.generateLisTasks();
      } 
    })
    .catch ((error: any) => {
      console.log('readParamTasksRefByType : ' + error);
      return;
    });

  }
  excludeManufacturerAttNotRevisions(tasksRead1: WksTasksModel[]): WksTasksModel[] {
    const filtredTasksList: WksTasksModel[] = [];
    for (const taskCur of tasksRead1) {
      const taskRefJson = JSON.parse(taskCur.taskrefJson) as TaskrefJsonModel;
      if (taskRefJson.jobManufacturerAtt !== undefined) {
        const jobManufacturerAtt = taskRefJson.jobManufacturerAtt;
        if (!jobManufacturerAtt.specType.startsWith('revision')) {
          continue;
        } else {
          filtredTasksList.push(taskCur);
        } 
      } else {
        filtredTasksList.push(taskCur);
      } 
    }
    return filtredTasksList;
  }
  generateLisTasks(): void  {

    this.tasksReferenced = [...this.listTasksCur];
    this.affectTooltip(this.tasksReferenced);
    for ( const taskRef of this.tasksReferenced) {
      taskRef.selectedTask = false;
      taskRef.taskrefLabel += (taskRef.equipBrand ? ' - ' + taskRef.equipBrand : '' );
      taskRef.taskrefLabel += (taskRef.equipModel ? ' - ' + taskRef.equipModel : '' );
    }
    if ((this.workTasksContentInput !== undefined) && (this.workTasksContentInput !== null) && (this.workTasksContentInput.length > 0 )) {      
      for (const taskSel of this.workTasksContentInput) {
        try { // 0
          const taskrefJson = JSON.parse(taskSel.taskrefJson);
          const tasksActionsContent  = taskrefJson.tasksActionsContent as TasksActionsContentModel;
          if (tasksActionsContent !== undefined) {
            // this.tasksActionsContent = taskrefJson.tasksActionsContent as TasksActionsContentModel;
            try { // 1
              if (tasksActionsContent.equipments)  {
                if (tasksActionsContent.equipments.equipId) {
                  for (const equipIdCur of tasksActionsContent.equipments.equipId) {
                    if (equipIdCur === this.rowEquip[this.equipIdx].equipId) {
                      for ( const taskRef of this.tasksReferenced) {
                        if (taskRef.taskrefId === taskSel.taskrefOrig) {
                          // this.tasksReferenced.splice(numRow, 1);
                          taskRef.selectedTask = true;
                          break;
                        }
                      }
                    }
                  }
                }
              }
            } catch (error) { // 1
            } finally { }
          } 
        } catch (error) { // 0
        } finally { }

      }
    }
    this.cacheTasksList = [...this.tasksReferenced];
  }

  affectTooltip(tasksList: WksTasksModel[]): void {

    for (const taskRef of tasksList) {
        let taskrefSN: TaskSN[] = [];

        if ((taskRef.taskrefSN !== undefined) && (taskRef.taskrefSN !== null)) {
          try {
            const taskrefSNJson = JSON.parse(taskRef.taskrefSN);
            taskrefSN = [...taskrefSNJson];
          } catch {
            taskrefSN = [];
          }
        }
        let tooltipTmp = '';
        let idxRow = 0;
        if (taskrefSN.length > 0) { 
          if (tooltipTmp === '') { 
            tooltipTmp = this._translate.getTranslate('snFrom') + ' : ' + taskrefSN[idxRow].snFrom;
            tooltipTmp += ' >> ';
            tooltipTmp += this._translate.getTranslate('snUpto') + ' : '  + taskrefSN[idxRow].snUpto ;
            /* tooltipTmp += '\n';
            tooltipTmp += 'test';*/
          } else  { 
            tooltipTmp += '\n';
            tooltipTmp += this._translate.getTranslate('snFrom') + ' : ' + taskrefSN[idxRow].snFrom;
            tooltipTmp += ' >> ';
            tooltipTmp += this._translate.getTranslate('snUpto') + ' : '  + taskrefSN[idxRow].snUpto ;
          }
          idxRow++;
        } else {
          tooltipTmp = this._translate.getTranslate('allSN');
        } 
        taskRef.snTooltip = tooltipTmp;
      }
  }


  getRestrictedList(tasksRead: WksTasksModel[], _equipSN: string): WksTasksModel[] {
    const restrictedList: WksTasksModel[] = [];

    for ( const taskCur of tasksRead) {
     
      if (taskCur.taskrefSN === null 
        || taskCur.taskrefSN === undefined 
        || taskCur.taskrefSN === '') {
          restrictedList.push(taskCur);
          continue;
      } else {
        let okToList: boolean;
        let taskrefSN: TaskSN[] = [];
        try {
          const taskrefSNJson = JSON.parse(taskCur.taskrefSN);
          taskrefSN = [...taskrefSNJson];
          okToList = this.searchSNRange(taskrefSN, _equipSN);
        } catch {
          taskrefSN = [];
          okToList = false;
        }

        if (okToList) {
          restrictedList.push(taskCur);
          continue;
        }
      }
        
    }

    return restrictedList;
  } 
  searchSNRange(parentSN: TaskSN[], _equipSN: string): boolean {
    let okToList: boolean;
    let fromOK: boolean;
    let uptoOK: boolean;
    let betweenOK: boolean;
    let testBetween: boolean;
    for (const parentItemSN of parentSN ) {
      okToList = false;
      fromOK = false;
      uptoOK = false;
      betweenOK = false;
      testBetween = true;
      if (parentItemSN.snFrom === null || parentItemSN.snFrom === undefined || parentItemSN.snFrom === '') {
        fromOK = true;
        betweenOK = true;
        testBetween = false;
      }
      if (parentItemSN.snUpto === null || parentItemSN.snUpto === undefined || parentItemSN.snUpto === '') {
        uptoOK = true;
        betweenOK = true;
        testBetween = false;
      }
      if (testBetween) {
        if (_equipSN < parentItemSN.snFrom ) {
          fromOK = false;
        } else {
          fromOK = true;
        }
        if (_equipSN > parentItemSN.snUpto ) {
          uptoOK = false;
        } else {
          uptoOK = true;
        }
        if (uptoOK && fromOK ) {
          betweenOK = true;
        }
      }
      if (uptoOK && fromOK && betweenOK) {
        okToList = true;
        break;
      }
    }
    return okToList;
  }
  onKeyFilterTasks($event: any) {
    // console.log(value);
    if ( ($event === '' ) || ($event.target.value === '' )) {
      this.tasksReferenced = [...this.cacheTasksList];
      this.affectTooltip(this.tasksReferenced);
      return;
    }
    let valueFinal: any;
    if ($event === '') {
      valueFinal = '';
    } else  {
      valueFinal = $event.target.value;
      if ( $event.keyCode === 32) {
        valueFinal += ' ';
      }
    }
    this.tasksReferenced = this.searchTasks(valueFinal);
    this.affectTooltip(this.tasksReferenced);
    this.filterTasks.nativeElement.value = valueFinal;
  }
  searchTasks(value: string) { 
    const filter = value.toLowerCase();
    return this.tasksReferenced.filter(taskCur => taskCur.taskrefLabel.toLowerCase().includes(filter));
  }
  // managing sidenav content
  dropTasks(event: CdkDragDrop<string[]>) {

    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
 
    // ranking calcul
    this.taskRanking();

  }
  referencedToSelected(_idx: number) {
   
    if (this.workTasksContentInput === undefined || this.workTasksContentInput === null) {
      this.workTasksContentInput = [];
    }

    this.openedAccordion = true;
    this.parentTask = {
      rootParam:  this.tasksReferenced[_idx],
      parentParam:  this.tasksReferenced[_idx],
      statutCall: 'sidenav',
      stdEntity: this._userService.getUserLogged().entity, 
      userlang: this._userService.getUserLogged().userlang
    };
    this.tasksActionsParentContent = undefined;
    this.tasksActionsContent = undefined;
    const taskrefJson = JSON.parse( this.tasksReferenced[_idx].taskrefJson);

    const tasksActionsContent  = taskrefJson.tasksActionsContent;
    if (tasksActionsContent !== undefined) {
      this.tasksActionsContent = taskrefJson.tasksActionsContent as TasksActionsContentModel;
      this.tasksActionsContent.equipments = { all: false, equipId: [this.rowEquip[this.equipIdx].equipId] };
      this.allEquipmentsCheck = true;
    }
    if (taskrefJson.tasksActionsParentContent !== undefined) {
      this.tasksActionsParentContent = taskrefJson.tasksActionsParentContent as TasksActionsContentModel;
    }
    const taskSelectedTmp = JSON.parse(JSON.stringify( this.tasksReferenced[_idx]));
    taskSelectedTmp.taskrefOrig = this.tasksReferenced[_idx].taskrefId;
    taskSelectedTmp.taskrefId = this.tasksReferenced[_idx].taskrefId;
    taskSelectedTmp.id = undefined;
    taskSelectedTmp.disabledTask = false;
    taskrefJson.tasksActionsContent = this.tasksActionsContent;
    taskrefJson.tasksActionsParentContent = ( this.tasksActionsParentContent ? this.tasksActionsParentContent : undefined);
    taskSelectedTmp.taskrefJson = JSON.stringify(taskrefJson);
    this.workTasksContentInput.push(taskSelectedTmp);

    this.rowTask = this.workTasksContentInput.length - 1 ;

    this.tasksReferenced[_idx].selectedTask = true;
    if (this.verifPrincing()) {
      this.dataOut.emit(this.workTasksContentInput);
    }

  }
  taskSelectRemoveOK(_idx: number, workTasksContentInput: WksTasksModel) {
    this.displayMessage('removeTask', workTasksContentInput.taskrefLabel, _idx);
  }
  // old code
  taskSelectedRemove(_idx: number) {

    const taskSelectedTmp = JSON.parse(JSON.stringify(this.workTasksContentInput[_idx]));
    try {
      if (this.tasksActionsContent.equipments)  {
        this.tasksActionsContent.equipments = undefined;
      }
    } catch (error) {
    } finally { }

    if (this.workTasksContentInput[_idx].id === undefined) {
      this.workTasksContentInput.splice(_idx, 1);
    } else {
      this.workTasksContentInput[_idx].disabledTask = true;
    }
    let isFound = false;
    for ( const taskCur of this.tasksReferenced) {
      if (taskCur.taskrefId === taskSelectedTmp.taskrefOrig) {
        isFound = true;
        taskCur.selectedTask = false;
        break;
      }
    }
    if (!isFound) {
      taskSelectedTmp.taskrefId = taskSelectedTmp.taskrefOrig;
      taskSelectedTmp.taskrefOrig = undefined;
      this.tasksReferenced.push(taskSelectedTmp);
      this.affectTooltip(this.tasksReferenced);
    }
      
    this.openedAccordion = false;
    this.rowTask = this.workTasksContentInput.length - 1 ;
    this.allEquipmentsCheck = false;
    if (this.verifPrincing()) {
      this.dataOut.emit(this.workTasksContentInput);
    }
  }
  readSelectedTaskValues( _idx: number) {
    this.openedAccordion = true;
    this.rowTask = _idx;
    this.parentTask = {
      rootParam: this.workTasksContentInput[_idx],
      parentParam: this.workTasksContentInput[_idx],
      statutCall: 'sidenav',
      stdEntity: this._userService.getUserLogged().entity, 
      userlang: this._userService.getUserLogged().userlang
    };
    this.tasksActionsParentContent = undefined;
    this.tasksActionsContent = undefined;

    if (this.workTasksContentInput[_idx].taskrefJson !== undefined) {
      const taskrefJson = JSON.parse(this.workTasksContentInput[_idx].taskrefJson);
      const tasksActionsContent  = taskrefJson.tasksActionsContent;
      if (tasksActionsContent !== undefined) {
        this.tasksActionsContent = taskrefJson.tasksActionsContent as TasksActionsContentModel;
        try {
          if (this.tasksActionsContent.equipments)  {
            this.allEquipmentsCheck = this.tasksActionsContent.equipments.all;
            this.equipListCheck = [];
            if (this.tasksActionsContent.equipments.equipId) {
              for (const equipIdCur of this.tasksActionsContent.equipments.equipId) {
                this.equipListCheck.push(equipIdCur);
              }
            }
          }
        } catch (error) {
        } finally { }
      } 
      if (taskrefJson.tasksActionsParentContent !== undefined) {
        this.tasksActionsParentContent = taskrefJson.tasksActionsParentContent as TasksActionsContentModel;
      }
    }
  }
  refreshTaskReferenced(): void {
  
    const revisionsList = this.getRevisionList(this.rowEquip[this.equipIdx].revisions);

    this.loadParamTasksRefByType(this.rowEquip[this.equipIdx].equipType, 
                            this.rowEquip[this.equipIdx].equipBrand, 
                            this.rowEquip[this.equipIdx].equipModel, 
                            this.rowEquip[this.equipIdx].equipSN,
                            revisionsList);
  }
  getRevisionList(revisions: RevisionModel[]): string {
    let revisionsList = '';
    let idxRevision = 0;
    for (const revision of revisions) {
      if (idxRevision === 0) {
        revisionsList = revision.taskrefId;
      } else  {
        revisionsList += ';' +  revision.taskrefId;
      }
      idxRevision++;
    }
    return revisionsList;
  }
  addTaskReferenced(): void {
    if (this.workTasksContentInput === undefined) {
      this.workTasksContentInput = [];
    }
    // this.tasksrefToUpdate = undefined;
    this.parentTaskRef = {
      rootParam: undefined,
      parentParam: undefined,
      statutCall: 'modal',
      stdEntity: this._userService.getUserLogged().entity, 
      userlang: this._userService.getUserLogged().userlang
    };
    this.openTaskRefModal();
    // this.isOkToSave = true;
  }
  addTaskSpecific() {
    if (this.workTasksContentInput === undefined || this.workTasksContentInput === null) {
      this.workTasksContentInput = [];
    }
    const ParamTasksrefTmp: WksTasksModel = {
      taskrefLabel: '',
      taskrefJson: undefined,
      disabledTask: false,
      ranking: 0,
    };
    this.workTasksContentInput.push(ParamTasksrefTmp);
    
    this.taskRanking();
    // this.tasksrefToUpdate = undefined;
    this.parentTask = {
      rootParam: undefined,
      parentParam: undefined,
      statutCall: 'sidenav',
      stdEntity: this._userService.getUserLogged().entity, 
      userlang: this._userService.getUserLogged().userlang
    };
    this.tasksActionsContent = undefined;
    this.tasksActionsParentContent = undefined;
    // this.isOkToSave = true;
  }
  taskRanking() {
    let idx = -1;
    for (const curRow of this.workTasksContentInput) {
      idx++;
      curRow.ranking = idx;
    }
    // this.rowTask = this.workTasksContentInput.length - 1;
  }
  setTaskLabelValue(_value: any, _idx: number) {
    this.workTasksContentInput[_idx].taskrefLabel = _value;
  }
  onChangeActionSidenav(_value: string) {

    // console.log('sidenav action ' + _value);
  }
  changeCheckEquipments(value: boolean) {
    this.tasksActionsContent.equipments.all = value;
    this.allEquipmentsCheck = value;
    this.equipListCheck = [];
    if (this.allEquipmentsCheck) {
      this.setContentTask('equipments', this.tasksActionsContent);
    }
  }
  changeListEquipments(value: any) {
    // console.log(value);
    this.tasksActionsContent.equipments.equipId = value;
    if (this.equipListCheck === undefined) {
      this.equipListCheck = [];
    }
    this.equipListCheck.push(value);

    this.setContentTask('equipments', this.tasksActionsContent);
  }

  setContentTask(_origEvent: string, _contentEvent: TasksActionsContentModel) {
    // const rowLocal = parseInt(_origEvent, 0); 
   
    if (this.tasksActionsContent === undefined) {
      const equipIds: string [] = [];
      equipIds.push(this.equipList[this.equipIdx].value);
      this.tasksActionsContent = {
        works: [],
        supplies : [],
        equipments: {
          all: false,
          equipId: equipIds
        },
      };
    }

    let rowTask: number;
    if ((_origEvent === 'supplies&parts') && (_contentEvent.supplies)) {
      this.allAvailable = true;
      const contentSupplies: TasksActionsModel[] = [];
      const parentSupplies: TasksActionsModel[] = [];
      rowTask = _contentEvent.rowTask;
      for (const itemSupplie of  _contentEvent.supplies) {
        if (itemSupplie.parentElement) {
          parentSupplies.push(itemSupplie);
        } else  {
          contentSupplies.push(itemSupplie);
        }
      }
      this.tasksActionsContent.supplies = contentSupplies;
      if (this.tasksActionsParentContent !==  undefined) {
        this.tasksActionsParentContent.supplies = parentSupplies;
      }

      // this.tasksActionsContent.supplies = _contentEvent.supplies;
      for (const wksTask of this.tasksActionsContent.supplies ) {
        if (!wksTask.available) {
          this.allAvailable = false;
        }
      }
      if (this.tasksActionsParentContent !==  undefined) {
        for (const wksTask of this.tasksActionsParentContent.supplies ) {
          if (!wksTask.available) {
            this.allAvailable = false;
          }
        }
      }
    }
    if ((_origEvent === 'works') && (_contentEvent.works)) {
      const contentWorks: TasksActionsModel[] = [];
      const parentWorks: TasksActionsModel[] = [];
      rowTask = _contentEvent.rowTask;
      for (const itemWork of  _contentEvent.works) {
        if (itemWork.parentElement) {
          parentWorks.push(itemWork);
        } else  {
          contentWorks.push(itemWork);
        }
      }
      this.tasksActionsContent.works = contentWorks;
      if (this.tasksActionsParentContent !==  undefined) {
        this.tasksActionsParentContent.works = parentWorks;
      }

      // this.tasksActionsContent.works = _contentEvent.works;
      rowTask = _contentEvent.rowTask;
    }
    if ((_origEvent === 'equipments') && (_contentEvent.equipments)) {
      this.tasksActionsContent.equipments = _contentEvent.equipments;
      rowTask = this.rowTask;
    }
    /*=====================================*/
    if (this.workTasksContentInput !== null 
          && this.workTasksContentInput !== undefined 
          && this.workTasksContentInput[rowTask] !== undefined 
          && this.workTasksContentInput[rowTask].taskrefJson !== undefined) {
      const taskrefJson = JSON.parse(this.workTasksContentInput[rowTask].taskrefJson) as TaskrefJsonModel;
      taskrefJson.tasksActionsContent = this.tasksActionsContent;
      taskrefJson.tasksActionsParentContent = ( this.tasksActionsParentContent ? this.tasksActionsParentContent : undefined);
      this.workTasksContentInput[rowTask].taskrefJson =  JSON.stringify(taskrefJson);
    } else  {
      const taskrefJson: TaskrefJsonModel = {
        tasksActionsContent: this.tasksActionsContent,
        tasksActionsParentContent: ( this.tasksActionsParentContent ? this.tasksActionsParentContent : undefined),
        tasksActionCompleted: undefined
      };
      /*
      if (this.workTasksContentInput !== null ||
        this.workTasksContentInput !== undefined ) {
          this.workTasksContentInput[rowTask].taskrefJson =  JSON.stringify(taskrefJson);
        }
      */  
      if (this.workTasksContentInput !== null &&
        this.workTasksContentInput !== undefined ) {
          this.workTasksContentInput[rowTask].taskrefJson =  JSON.stringify(taskrefJson);
        }  
    }
    /*=====================================*/
    /* this.workTasksContentInput[rowTask].taskrefJson =  '{"tasksActionsContent": ' + 
          (this.tasksActionsContent === undefined ? '' : JSON.stringify(this.tasksActionsContent) )
            + '}';*/
    if (this.verifPrincing()) {
   
      this.dataOut.emit(this.workTasksContentInput);
    }

  }

  verifPrincing(): boolean {
    if (this.workTasksContentInput === undefined 
      || this.workTasksContentInput === null 
      || this.workTasksContentInput.length === 0) {
      return false;
    }
    let idxRow = 0;
    let pricingOK = true; 
    let tasksActions: TasksActionsContentModel;
    for (const taskCur of this.workTasksContentInput) {
      if (taskCur.disabledTask) {
        continue;
      }
      
      try {
        const taskrefJson = JSON.parse( taskCur.taskrefJson);

        const tasksActionsContent  = taskrefJson.tasksActionsContent;
        if (tasksActionsContent !== undefined) {
          tasksActions = taskrefJson.tasksActionsContent as TasksActionsContentModel;

          if (tasksActions.supplies) {
            this.waitPricing[idxRow] = this.verifActionPricing(tasksActions.supplies);
            this.pricingEquip(tasksActionsContent.equipments.equipId, idxRow, this.waitPricing[idxRow] );
          } 
          if (this.waitPricing[idxRow])  {
            pricingOK = false;
            this.toggleValidOK(false);
            break;
          }
          if (tasksActions.works) {
            this.waitPricing[idxRow] = this.verifActionPricing(tasksActions.works);
            this.pricingEquip(tasksActionsContent.equipments.equipId, idxRow, this.waitPricing[idxRow] );
          } 
          if (this.waitPricing[idxRow])  {
            pricingOK = false;
            this.toggleValidOK(false);
            break;
          }
        }
        if (taskrefJson.tasksActionsParentContent !== undefined) {
          tasksActions = taskrefJson.tasksActionsParentContent as TasksActionsContentModel;
          if (tasksActions.supplies) {
            this.waitPricing[idxRow] = this.verifActionPricing(tasksActions.supplies);
            this.pricingEquip(tasksActionsContent.equipments.equipId, idxRow, this.waitPricing[idxRow] );
          } 
          if (this.waitPricing[idxRow])  {
            pricingOK = false;
            this.toggleValidOK(false);
            break;
          }
          if (tasksActions.works) {
            this.waitPricing[idxRow] = this.verifActionPricing(tasksActions.works);
            this.pricingEquip(tasksActionsContent.equipments.equipId, idxRow, this.waitPricing[idxRow] );
          } 
          if (this.waitPricing[idxRow])  {
            pricingOK = false;
            this.toggleValidOK(false);
            break;
          }
        }
      } catch (error) {
      } finally { }
      idxRow++;
    }
    return pricingOK;
  }
  verifActionPricing(taskListCur: TasksActionsModel[]): boolean {
    let waitPrincing = false;
    for (const actionCur of taskListCur) {
      if (actionCur.extPrice === 0 || actionCur.extPrice === undefined  || actionCur.extPrice <= 0 ) {
        waitPrincing = true;
      }
    }
    return waitPrincing;
  }
  pricingEquip(equipId: string[], taskRow: number, waitPricing: boolean): void {
    for ( const equipCur of equipId) {
      for (const rowEquipCur of this.rowEquip) {
        if (rowEquipCur.equipId === equipCur) {
          rowEquipCur.waitPricing[taskRow] = waitPricing;
          break;
        }
      }
    }
  }
  // start new version code
  initRowEquip(): void {
    this.rowEquip = [];
    let idxRow = 0;
    for (const equipRow of this.equipItem.equipList) {
      const rowCur = {
        equipId: equipRow.value,
        equipSN: (equipRow.otherData !== null ? equipRow.otherData : ''),
        detailCollapse: true,
        equipType: this.equipItem.equipType,
        equipBrand: this.equipItem.equipBrand,
        equipModel: this.equipItem.equipModel,
        revisions: equipRow.otherValues,
        isEven: (idxRow % 2 === 0 ? true : false),
        isOdd: (idxRow % 2 !== 0 ? true : false),
        selected: false,
        workTasksEquip: [],
        waitPricing: []
      };
      this.rowEquip.push(rowCur);
      idxRow++;
    }
    if (this.workTasksContentInput === null 
      || this.workTasksContentInput === undefined 
      || this.workTasksContentInput.length === 0) {
      this.okToDisplay = true;
      return;
    }
    let tasksActions: TasksActionsContentModel;
    let pricingOK = 0;
    let idxTask = 0;
    for (const taskRefContent of this.workTasksContentInput) {
      pricingOK = 0;
      taskRefContent.ranking = idxTask;
      const taskrefJson = JSON.parse(taskRefContent.taskrefJson);
      if (taskrefJson.tasksActionsParentContent !== undefined) {
        tasksActions = taskrefJson.tasksActionsParentContent as TasksActionsContentModel;
        if (tasksActions.supplies) {
          pricingOK = + (this.verifActionPricing(tasksActions.supplies) ? 1 : 0 );
        } 

        if (tasksActions.works) {
          pricingOK = + (this.verifActionPricing(tasksActions.works) ? 1 : 0 );
        } 
      } else  {
        pricingOK = 2;
      }
      const tasksActionsContent  = taskrefJson.tasksActionsContent;
      
      if (tasksActionsContent !== undefined) {
        tasksActions = taskrefJson.tasksActionsContent as TasksActionsContentModel;
        if (tasksActions.supplies) {
          pricingOK = + (this.verifActionPricing(tasksActions.supplies) ? 1 : 0 );
        } 

        if (tasksActions.works) {
          pricingOK = + (this.verifActionPricing(tasksActions.works) ? 1 : 0 );
        } 
      
        try {
          if (tasksActionsContent.equipments)  {
            if (tasksActionsContent.equipments.all) {
              for (const rowEquipCur of this.rowEquip) {
                rowEquipCur.workTasksEquip.push(taskRefContent);
                rowEquipCur.waitPricing.push((pricingOK === 4 ? true : false));
              }
            } else {
              for ( const equipCur of tasksActionsContent.equipments.equipId) {
                for (const rowEquipCur of this.rowEquip) {
                  if (rowEquipCur.equipId === equipCur) {
                    rowEquipCur.workTasksEquip.push(taskRefContent);
                    rowEquipCur.waitPricing.push((pricingOK === 4 ? true : false));
                  }
                }
              }
            }
          }
        } catch (error) {
        } finally { }
      } 
      idxTask++;
    }
    this.okToDisplay = true;
  }
  readEquipSelectedTaskValues(_idxEquip: number, _idxTask: number, workTasksContentInput: WksTasksModel) {
   
    for (const rowCur of this.rowEquip) {
      rowCur.selected = false;
    }
    this.rowEquip[_idxEquip].selected = true;

    if (this.equipIdx !== _idxEquip) {
      this.equipIdx = _idxEquip;
      const revisionsList = this.getRevisionList(this.rowEquip[this.equipIdx].revisions);
      this.loadParamTasksRefByType(this.rowEquip[_idxEquip].equipType, 
                            this.rowEquip[_idxEquip].equipBrand, 
                            this.rowEquip[_idxEquip].equipModel, 
                            this.rowEquip[_idxEquip].equipSN,
                            revisionsList);
    }

    this.rowTask = workTasksContentInput.ranking;

    this.taskRowDisplay = _idxTask;
    this.parentTask = {
      rootParam: workTasksContentInput,
      parentParam: workTasksContentInput,
      statutCall: 'sidenav',
      stdEntity: this._userService.getUserLogged().entity, 
      userlang: this._userService.getUserLogged().userlang
    };
    this.tasksActionsParentContent = undefined;
    this.tasksActionsContent = undefined;

    if (workTasksContentInput.taskrefJson !== undefined) {
      const taskrefJson = JSON.parse(workTasksContentInput.taskrefJson);
      this.tasksActionsContent  = taskrefJson.tasksActionsContent as TasksActionsContentModel;
      
      if (taskrefJson.tasksActionsParentContent !== undefined) {
        this.tasksActionsParentContent = taskrefJson.tasksActionsParentContent as TasksActionsContentModel;
      }
    }
    this.openedAccordion = true;
  }
  toggleRowEquip(idxRow: number): void {
    this.equipIdx = idxRow;
    this.rowEquip[idxRow].detailCollapse = !this.rowEquip[idxRow].detailCollapse;
    this.tasksActionsContent = undefined;
    this.tasksActionsParentContent = undefined;
    this.openedAccordion = false;
    if (this.rowEquip[idxRow].detailCollapse) {
      this.rowEquip[idxRow].selected = false;
    } else {
      const revisionsList = this.getRevisionList(this.rowEquip[idxRow].revisions);
      this.loadParamTasksRefByType(this.rowEquip[idxRow].equipType, this.rowEquip[idxRow].equipBrand, this.rowEquip[idxRow].equipModel, this.rowEquip[idxRow].equipSN, revisionsList);
    }
    
    return;
  }
  setEquipTaskLabelValue(_value: any, _idxEquip: number, _idxTask: number) {
    this.rowEquip[_idxEquip].workTasksEquip[_idxTask].taskrefLabel = _value;
  }
  addTaskEquipSelect(_idxEquip: number): void {
    if (this.workTasksContentInput === undefined || this.workTasksContentInput === null) {
      this.workTasksContentInput = [];
    }
    this.equipIdx = _idxEquip;
    const revisionsList = this.getRevisionList(this.rowEquip[this.equipIdx].revisions);
    this.loadParamTasksRefByType(this.rowEquip[_idxEquip].equipType, this.rowEquip[_idxEquip].equipBrand, this.rowEquip[_idxEquip].equipModel, this.rowEquip[_idxEquip].equipSN, revisionsList);
    const ParamTasksrefTmp: WksTasksModel = {
      taskrefLabel: '',
      taskrefJson: undefined,
      disabledTask: false
    };
    this.rowTask = this.workTasksContentInput.length;
    this.workTasksContentInput.push(ParamTasksrefTmp);
    this.taskRanking();
    this.parentTask = {
      rootParam: undefined,
      parentParam: undefined,
      statutCall: 'sidenav',
      stdEntity: this._userService.getUserLogged().entity, 
      userlang: this._userService.getUserLogged().userlang
    };
    this.tasksActionsContent = undefined;
    this.tasksActionsParentContent = undefined;

    this.rowEquip[_idxEquip].workTasksEquip.splice(this.rowTask , 0, ParamTasksrefTmp) ;
    this.rowEquip[_idxEquip].waitPricing.splice(this.rowTask , 0, true) ;
    this.toggleValidOK(false);
    if (this.rowEquip[_idxEquip].detailCollapse) {
      this.rowEquip[_idxEquip].detailCollapse = false;
    }
    for (const rowCur of this.rowEquip) {
      rowCur.selected = false;
    }
    this.rowEquip[_idxEquip].selected = true;
    
  }
  referencedToSelectedEquip(_idx: number) {
   
    if (this.workTasksContentInput === undefined || this.workTasksContentInput === null) {
      this.workTasksContentInput = [];
    }
    this.rowEquip[this.equipIdx].workTasksEquip[this.taskRowDisplay] = this.tasksReferenced[_idx];
    this.toggleSidenavRight('');

    this.parentTask = {
      rootParam:  this.tasksReferenced[_idx],
      parentParam:  this.tasksReferenced[_idx],
      statutCall: 'sidenav',
      stdEntity: this._userService.getUserLogged().entity, 
      userlang: this._userService.getUserLogged().userlang
    };
    this.tasksActionsParentContent = undefined;
    this.tasksActionsContent = undefined;
    const taskrefJson = JSON.parse( this.tasksReferenced[_idx].taskrefJson);

    const tasksActionsContent  = taskrefJson.tasksActionsContent;
    if (tasksActionsContent !== undefined) {
      this.tasksActionsContent = taskrefJson.tasksActionsContent as TasksActionsContentModel;
      this.tasksActionsContent.equipments = { all: false, equipId: [this.equipItem.equipList[this.equipIdx].value] };
      this.allEquipmentsCheck = false;
    }
    if (taskrefJson.tasksActionsParentContent !== undefined) {
      this.tasksActionsParentContent = taskrefJson.tasksActionsParentContent as TasksActionsContentModel;
    }
    const taskSelectedTmp = JSON.parse(JSON.stringify( this.tasksReferenced[_idx]));
    taskSelectedTmp.taskrefOrig = this.tasksReferenced[_idx].taskrefId;
    taskSelectedTmp.taskrefId = this.tasksReferenced[_idx].taskrefId;
    taskSelectedTmp.id = undefined;
    taskSelectedTmp.disabledTask = false;
    taskrefJson.tasksActionsContent = this.tasksActionsContent;
    taskrefJson.tasksActionsParentContent = ( this.tasksActionsParentContent ? this.tasksActionsParentContent : undefined);
    taskSelectedTmp.taskrefJson = JSON.stringify(taskrefJson);
    taskSelectedTmp.ranking = this.rowTask;
    /* old code
    this.workTasksContentInput.push(taskSelectedTmp);

    this.rowTask = this.workTasksContentInput.length - 1 ;
    */
   // New code
    this.workTasksContentInput[this.rowTask] = taskSelectedTmp;

    this.tasksReferenced[_idx].selectedTask = true;
    if (this.verifPrincing()) {
      this.dataOut.emit(this.workTasksContentInput);
    }
    this.openedAccordion = true;
  }
  taskEquipSelectRemoveOK(_idxEquip: number, _idxTask: number, workTasksContentInput: WksTasksModel): void {

    // const messageContent = workTasksContentInput.taskrefLabel + ' \n ' + this.equipList[_idxEquip].label + ' (SN ' +  this.equipList[_idxEquip].otherData + ') ';
    const messageContent = '<div><b>' + this._translate.getTranslate('removeTask') + ' : ' + workTasksContentInput.taskrefLabel + '</b><br>' + 
                          this._translate.getTranslate('equipment') + ' : ' + 
                          this.equipList[_idxEquip].label + ' (SN ' +  this.equipList[_idxEquip].otherData + ') ?' + '</div>' ;
    
    this.displayMessage('removeEquipTask', messageContent, _idxEquip, _idxTask);
  }
  taskEquipSelectedRemove(_idxEquip: number, _idxTask: number) {

    const taskToRemove: WksTasksModel = this.rowEquip[_idxEquip].workTasksEquip[_idxTask];
    let taskToRemovable: WksTasksModel;
    let idxToRemove = -1;
    for (const taskTmp of this.workTasksContentInput) {
      if (taskTmp.taskrefId ===  undefined && taskTmp.ranking === taskToRemove.ranking) {
        this.workTasksContentInput.splice(taskTmp.ranking, 1);
        this.rowEquip[_idxEquip].workTasksEquip.splice(_idxTask, 1);
        continue;
      }
      if (taskTmp.taskrefId === taskToRemove.taskrefId) {
        taskToRemovable = taskTmp;
        idxToRemove++;
        try {
          const taskrefJson = JSON.parse( taskTmp.taskrefJson);
          const tasksActionsContent  = taskrefJson.tasksActionsContent;

          if (tasksActionsContent.equipments.all ) {
            tasksActionsContent.equipments.all = false;
            tasksActionsContent.equipments.equipId = [];
            for (const equipCur of this.rowEquip) {
              if (equipCur.equipId !== this.rowEquip[_idxEquip].equipId) {
                tasksActionsContent.equipments.equipId.push(equipCur.equipId);
              }
            }
          } else { 
            let equipTmp = 0;
            for ( const equipCur of tasksActionsContent.equipments.equipId)  {
              if (equipCur === this.rowEquip[_idxEquip].equipId) {
                tasksActionsContent.equipments.equipId.splice(equipTmp, 1);
                break;
              }
              equipTmp++;
            }
          }
          taskTmp.taskrefJson = JSON.stringify(taskrefJson);
        } catch (error) {
        }
      }  
    }
    // this.rowEquip[_idxEquip].workTasksEquip.splice(_idxTask, 1);
    try {
      const taskrefJsonTmp = JSON.parse( this.workTasksContentInput[idxToRemove].taskrefJson);
      const tasksActionsContentTmp =  taskrefJsonTmp.tasksActionsContent;

      if (tasksActionsContentTmp.equipments.equipId.length === 0) { 
        const taskSelectedTmp = JSON.parse(JSON.stringify(this.workTasksContentInput[idxToRemove]));
        try {
          if (this.tasksActionsContent.equipments) {
            this.tasksActionsContent.equipments = undefined;
          }
        } catch (error) {
        } finally { }

        if (this.workTasksContentInput[idxToRemove].id === undefined) {
          this.workTasksContentInput.splice(idxToRemove, 1);
        } else {
          this.workTasksContentInput[_idxTask].disabledTask = true;
        }
        let isFound = false;
        for ( const taskCur of this.tasksReferenced) {
          if (taskCur.taskrefId === taskSelectedTmp.taskrefOrig) {
            isFound = true;
            taskCur.selectedTask = false;
            break;
          }
        }
        if (!isFound) {
          taskSelectedTmp.taskrefId = taskSelectedTmp.taskrefOrig;
          taskSelectedTmp.taskrefOrig = undefined;
          this.tasksReferenced.push(taskSelectedTmp);
          this.affectTooltip(this.tasksReferenced);
        }

      }
    } catch (error) {
      this.workTasksContentInput.splice(idxToRemove, 1);


    } finally {
      this.openedAccordion = false;
      this.allEquipmentsCheck = false;
      this.rowTask = this.workTasksContentInput.length - 1 ;
      this.dataOut.emit(this.workTasksContentInput);
      if (this.verifPrincing()) {
        this.toggleValidOK(true);
      }
     }
  }
  // sidenavRight
  toggleSidenavRight(_arg: string) {

    if (_arg === 'close') {
      this.rightSidenavCollapsed = true;
      try {
        this.sidenavRight.close();
      } catch (error) {
      } finally { }
      return;
    }
    this.rightSidenavCollapsed = !this.rightSidenavCollapsed;
    if (this.rightSidenavCollapsed) {
      try {
        this.sidenavRight.close();
      } catch (error) {
      } finally { }
    } else {
      try {
        this.sidenavRight.open();
      } catch (error) {
      } finally { }
    }
  }
  toggleValidOK(isValid: boolean): void {
    const validOK = {
      validOK: isValid
    };
    this.dataValidTasks.emit(validOK);
  }
  verifyStockParts(): any {
    const messageDisplay: MessageDisplay = {
      titleBox: 'verifyStock',
      messageLabel: 'verifyStockParts',
      messageArgs: [],
      messageType: 'ERROR',
      messageDatas: undefined,
      typeDialog: 'infoProgressWks',
      actionCur: 'verifyStockParts',
      progressDeterminate: false,
    };
    this.messageBoxDisplay(messageDisplay);
    this._wksCentralService.verifyStockParts(this.wksWorks.stdEntity, this.wksWorks.id)
    .subscribe(
      data => {
        this.closeMatDialog();
        const response = data.body as WksWorksModel;
        const messageDisplay1: MessageDisplay = {
          titleBox: 'verifyStock',
          messageLabel: 'verifyStockPartsDone',
          messageArgs: [],
          messageType: 'INFO',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'verifyStockPartsDone',
          progressDeterminate: false,
        };
        this.messageBoxDisplay(messageDisplay1);
        this.workTasksContentInput = response.workTasksContent;
        this.verifPrincing();
      },
      err => {
        this.closeMatDialog();
        const messageDisplay2: MessageDisplay = {
          titleBox: 'verifyStock',
          messageLabel: 'verifyStockPartsFailed',
          messageArgs: [],
          messageType: 'ERROR',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'verifyStockPartsFailed',
          progressDeterminate: false,
        };
        this.messageBoxDisplay(messageDisplay2);

      }
    );
  }
  // end new version code
  openTaskRefModal(): void {
    this.dataUpstream = {
      equipType: this.equipItem.equipType,
      equipBrand: this.equipItem.equipBrand,
      equipModel: this.equipItem.equipModel,
    };
    this.openModal(this.taskReferencedModal);
  }
  openModal(template: TemplateRef<any>) {

    // this.modalText = [docCur.docName];
    this.modalRef = this.modalService.show(template, this.configModal);
    // https://stackoverflow.com/questions/46408428/ngx-bootstrap-modal-how-to-get-a-return-value-from-a-modal
    /*
    this.modalRef.content.onClose.subscribe(result => {
      console.log('results', result);
    })
    */
  }
// ========================================================== errors alerts 
messageBoxDisplay(_messageDisplay: MessageDisplay): void {

  const dialogConfig = new MatDialogConfig();
  const messageBox = this._translate.instant(_messageDisplay.messageLabel, _messageDisplay.messageArgs );
  
  // const messageDisplayCACHE = JSON.parse(JSON.stringify(_messageDisplay));
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data = {
    id: 1,
    title: this._translate.getTranslate( _messageDisplay.titleBox),
    typeDialog: _messageDisplay.typeDialog,
    panelClass: 'stdTheme',
    contentMessage: messageBox,
    data1: _messageDisplay.messageDatas,
    data2: '',
    actionCur: _messageDisplay.actionCur,
    progressDeterminate: _messageDisplay.progressDeterminate,
    errorsList: undefined,
    messageType: _messageDisplay.messageType
  };
  this.messageDialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);
  
  this.messageDialogRef.afterClosed()
  .pipe(takeUntil(this.onDestroy))
  .subscribe(
    data => {
      // data: "okAction"
      if (data === 'okAction') {
        if (_messageDisplay.actionCur === 'verifyStockParts') {
          this.endProcessFile();
        }
        if (_messageDisplay.actionCur === 'verifyStockPartsDone') {
          this.endProcessFile();
        }
        if (_messageDisplay.actionCur === 'verifyStockPartsFailed') {
          this.endProcessFile();
        }
      }
    });
  }
  closeMatDialog(): void {
    this._dialog.closeAll();

    // console.log(this._dialog.openDialogs.length);
    if (this._dialog.openDialogs.length > 0) {

      for (const instCur of this._dialog.openDialogs) {
        this._dialog.openDialogs.splice(0, 1);
      }
    }
    this.messageDialogRef = null;
  }
  private endProcessFile() {
    this.closeMatDialog();

  }
  closeModal() {
    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    } 
  }
  displayMessage(_actionCur: string, messageContent?: string, _idxEquip?: number, _idxTask?: number) {

    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    let messageBox: string;
    if (_actionCur === 'removeTask') {
      messageBox = 'removeTask';
      titleBox = 'tasksBox';
    }
    if (_actionCur === 'removeEquipTask') {
      messageBox = 'removeTask';
      titleBox = 'tasksBox';
    }
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate(titleBox),
      typeDialog: 'confirmAction',
      typeDisplay: 'HTML',
      panelClass: 'stdTheme',
      contentMessage: (_actionCur === 'removeEquipTask' ? messageContent : this._translate.getTranslate(messageBox) + ' --> ' + messageContent + ' ?') ,
      data1: '',
      data2: '',
      messageType: 'ERROR'

      };
 //   this._dialog.open(ModalCommonComponent, dialogConfig);

    // const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);
    this.messageDialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);
    /*
    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(data => {
        // console.log('Dialog output:', data);
        if (data === 'okAction') {
          this.taskSelectedRemove(idx);
          // this.displayMessage('waitingInvoicing');
        }
      });
      */
    this.messageDialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(data => {
        // console.log('Dialog output:', data);
        if (data === 'okAction' && _actionCur === 'removeTask') {
          this.taskSelectedRemove(_idxTask);
          // this.displayMessage('waitingInvoicing');
        }
        if (data === 'okAction' && _actionCur === 'removeEquipTask') {
          this.taskEquipSelectedRemove(_idxEquip, _idxTask);
          // this.displayMessage('waitingInvoicing');
        }
      });

  }
}
