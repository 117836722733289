import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild, ChangeDetectorRef, AfterViewChecked, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { WksCentralService } from '../../../../services/wks-central.service';
import { UserService } from '../../../../../../services/user.service';
import { RessourcesService } from '../../../../../../job/common/services/ressources.service';
import { BootstrapI18nService } from '../../../../../../i18n/bootstrap/bootstrap-i18n.service';

import { WksEquipmentsRequest, WksEquipmentUsage } from '../../../../models/wks-works.model';
import { TranslateService } from '../../../../../../services/translate.service';
import { IhmOptions, WksTasksModel } from '../../../../models/wks-param.model';

import { CommonMethods } from '../../../../../../job/common/tools/commonMethods';

import { LabelValue } from '../../../../../../models/data.model';
import { MessageDisplay } from '../../../../../../models/common.model';
import { ModalCommonComponent } from '../../../../../../job/common/components/modal-common/modal-common.component';
import { environment } from '../../../../../../../environments/environment';

import { WksEquipmentModel } from '../../../../models/wks-equipment.model';

@Component({
  selector: 'mdi-equip-usage-hours',
  templateUrl: './equip-usage-hours.component.html',
  styleUrls: ['./equip-usage-hours.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EquipUsageHoursComponent implements OnChanges, AfterViewChecked {

  @Input() workEquipArgs: WksEquipmentsRequest;
  @Input() equipIdCall: string;
  @Input() optionsArg: any;
  @Input() ihmOptions: IhmOptions;
  @Input() randomIdString: string;
  @Input() okInputHours: boolean;
  @Input() equipmentToUpdate: WksEquipmentModel;
  
  private readonly onDestroy = new Subject<void>();

  usageHistoCache: WksEquipmentUsage[] = [];
  usageHistoInput: WksEquipmentUsage[] = [];

  fieldFormat: {
    locale: string;
    minDecimal: string;
    maxDecimal: string;
    currency: string;
    justifCurrency: string;
    justifText: string;
    justifNumbers: string;
    language: string;

  };
  datePickerConfig: Partial<BsDatepickerConfig>;
  timeFormat: string;
  dateFormat: string;
  dateFormatMoment: string;
  isOkToDisplay: boolean; 
  isOkToSave: boolean;
  isReadOnly: boolean;
  histoUsageForm: FormGroup;
  
  minDate: Date;
  maxDate: Date;

  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDate();

  minDayDate: Date;
  maxDayDate: Date;
  currentDateValue: Date[] = [];
  defaultDateRef: Date;

  listTasksCur: WksTasksModel[];
  listTasksFiltered: WksTasksModel[];
  listTasksCache: WksTasksModel[];
  listTasksLabel: LabelValue[];
  listLabelCache: LabelValue[];
  filteredTasksList: any;



  dialogRef: MatDialogRef<ModalCommonComponent, any>;
  
  constructor(private fb: FormBuilder, 
    private _userService: UserService,
    private cd: ChangeDetectorRef,
    private _dialog: MatDialog,
    private _bsI18nService: BootstrapI18nService,
    private _translate: TranslateService,
    private _ressources: RessourcesService,
    private _wksCentralService: WksCentralService) { }

  ngOnChanges(changes: SimpleChanges) {
    
    const listKey = Object.keys(changes);

    for (const propName of listKey) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'workEquipArgs': {
            this.workEquipArgs = changes['workEquipArgs'].currentValue;
            break;
          } // end case
            // equipIdCall
          case 'equipIdCall': {
            this.equipIdCall = changes['equipIdCall'].currentValue;
            break;
          } // end case
          // optionsArg
          case 'optionsArg': {
            this.optionsArg = changes['optionsArg'].currentValue;
            break;
          } // end case
          // ihmOptions
          case 'ihmOptions': {
            this.ihmOptions = changes['ihmOptions'].currentValue;
            break;
          }
          // randomIdString
          case 'randomIdString': {
            this.randomIdString = changes['randomIdString'].currentValue;
            break;
          }
          // okInputHours
          case 'okInputHours': {
            this.okInputHours = changes['okInputHours'].currentValue;
            break;
          }
        }  // end switch
      } // end if
    }// end loop
    this.initData();

  }
  
  ngAfterViewChecked() {
    this.cd.detectChanges();
  }
  
  initData() {
    
    if (!this.okInputHours) {
      return;
    }

    this.initDp();
    this.initBooleans();
    this.settingIntFmts();
    this.initParams();
    // this.buildForm();
    this.getHistoUsage();
  }
  initBooleans(): void {
    this.isOkToDisplay = false;
    this.isOkToSave = false;
    this.isReadOnly = false;
  }
  initParams(): void {
    this.isReadOnly = this.ihmOptions.readOnly;
    // this.currentDateValue = moment(new Date(), this.dateFormatMoment).toDate();
    this.defaultDateRef = new Date();
    this.datePickerConfig = {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: true,
        minDate: this.minDate,
        maxDate: this.maxDate,
        dateInputFormat: this.dateFormat,
        isAnimated: true
      };
    this._bsI18nService.setLanguage(this.fieldFormat.locale.toLowerCase());
    this.loadParamTasksRefByType(this.equipmentToUpdate.equipType, this.equipmentToUpdate.equipBrand, undefined)
          .then ((responseType2: any) => {
          })
          .catch ((error: any) => {
            console.log('loadParamTasksRefByType : ' + error);
            if (error === 404 ) {
        
            } else  {
             
            }
          });
  }
  initDp() {

    this.minDayDate = new Date(this.year - 10, this.month, this.day);
    this.maxDayDate = new Date();
    this.minDate = new Date(this.year - 100, this.month, this.day);
    this.maxDate =  new Date(this.year, this.month, this.day);

  }
  settingIntFmts(): boolean {
    const wksEntity = this._wksCentralService.getWksEntityCur();
    if (wksEntity === undefined) {
      return false;
    }
    const otherData = JSON.parse(wksEntity.otherData);
    const internationnalFormat = otherData.internationnalFormat;
    this.fieldFormat = {
      locale: this._ressources.getFormat(internationnalFormat, 'numbers', 'locale'),
      minDecimal: this._ressources.getFormat(internationnalFormat, 'numbers', 'minimumFractionDigits'),
      maxDecimal: this._ressources.getFormat(internationnalFormat, 'numbers', 'maximumFractionDigits'),
      currency: this._ressources.getFormat(internationnalFormat, 'numbers', 'currency'),
      justifCurrency: this._ressources.getFormat(internationnalFormat, 'justifs', 'currency'),
      justifText: this._ressources.getFormat(internationnalFormat, 'justifs', 'text'),
      justifNumbers: this._ressources.getFormat(internationnalFormat, 'justifs', 'numbers'),
      language:  this._ressources.getFormat(internationnalFormat, 'numbers', 'language'),
    };
    this.timeFormat = this._translate.getLocalFmt('timeFormat');
    this.dateFormat  = this._translate.getLocalFmt('dateFormat');

    this.dateFormatMoment = this.dateFormat;
    this.dateFormatMoment = this.dateFormatMoment.replace('dd', 'DD');
    this.dateFormatMoment = this.dateFormatMoment.replace('yyyy', 'YYYY');

    return true;
  }
  loadParamTasksRefByType(_equipType: string, _equipBrand: string, _equipModel: string) {

    this.listTasksCur = [];

    return new Promise((resolve, reject) => {
     
    this._wksCentralService.getParamTasksrefByTypeList(this._userService.getUserLogged().entity, 
                      _equipType, this._userService.getUserLogged().userlang, _equipBrand, _equipModel)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        const tasksRead = data.body;
        this.listTasksCur = [];
        this.listTasksCache = [];
        for (const taskCur of tasksRead) {
          if (!taskCur.taskrefJson) {
            continue;
          }
          const taskrefJson = JSON.parse(taskCur.taskrefJson);
          if (!taskrefJson.jobManufacturerAtt) {
            continue;
          }
          const jobManufacturerAtt = taskrefJson.jobManufacturerAtt;
      
          if (jobManufacturerAtt !== undefined) {
            taskCur.jobManufacturerAtt = JSON.parse(JSON.stringify(jobManufacturerAtt));
            const isRevision = (taskCur.jobManufacturerAtt.specType.startsWith('revision') ? true : false);
            if (isRevision) {
              this.listTasksCur.push(taskCur);
              continue;
            }
          }
        }
        
        /*
        if (_equipSN !== undefined && _equipSN !== null && _equipSN !== '') {
          this.listTasksCur = [];
          this.listTasksCur = this.getRestrictedList(tasksRead, _equipSN);
        }
        */
        // this.affectTooltip(this.tasksReferenced);
        this.listTasksCache = JSON.parse(JSON.stringify(this.listTasksCur));
        for ( const taskRef of this.listTasksCur) {

          const labelValue: LabelValue =  {
            label: taskRef.taskrefLabel,
            value: taskRef.taskrefLabel
          };
          taskRef.selectedTask = false;
          taskRef.taskrefLabel += (taskRef.equipBrand ? ' - ' + taskRef.equipBrand : '' );
          taskRef.taskrefLabel += (taskRef.equipModel ? ' - ' + taskRef.equipModel : '' );
        }
        this.listTasksFiltered = JSON.parse(JSON.stringify(this.listTasksCur));
        this.filteredTasksList = this.listTasksCur.slice();
        resolve(tasksRead);
      },
      err => {
        console.log(err);
        reject(err.status);
        }
      );
    });
  }
  initChangesSubscribe(): void {  
    
    this.histoUsageForm.valueChanges.subscribe(x => {
      this.ctrlInput();
    });
     
  }
  buildForm(): void {
    this.histoUsageForm = undefined;

    // this.toolServicesFa = new FormArray([this.buildToolServices()]);

    this.histoUsageForm = new FormGroup({
      histoUsageFa: this.fb.array([]) ,
    },
    {
      updateOn: 'blur'
    });
    this.initChangesSubscribe();
  }
  get histoUsageFa(): FormArray {
    return this.histoUsageForm.get('histoUsageFa') as FormArray;
  }
  newHistoUsageRow(): FormGroup {

    return this.fb.group({
      hoursFg: this.fb.control({value: '', disabled: this.ihmOptions.readOnly}, [Validators.required]),
      dateFg: this.fb.control({value: '', disabled: this.ihmOptions.readOnly}, [Validators.required]),
      histoRevisionFg: this.fb.control({value: true, disabled: this.ihmOptions.readOnly}, [Validators.required]),
      taskrefIdFg: this.fb.control({value: '', disabled: this.ihmOptions.readOnly}, [Validators.required]),
    });
  }
  addHistoUsageRow(): void {
    this.histoUsageFa.push(this.newHistoUsageRow());
  }
  removeHistoUsageRow(idxHistoUsage: number): void {
    this.histoUsageFa.removeAt(idxHistoUsage);
  }
  onHistoChange(_typeInput: string, inputValue: any, idxRow: number): void {
    // console.log(JSON.stringify(inputValue));
    // const argsInput = this.apiContentForm.get('toolArgsFa') as FormArray;
    let dateFormatted: any;

    switch (_typeInput) {
      case 'hours':
        if (this.usageHistoInput[idxRow].inputStatut === 'exists') {
          if (this.usageHistoInput[idxRow].histoHours === Number(inputValue)) {
            return;
          }
        }
        this.usageHistoInput[idxRow].histoHours = Number(inputValue);
        this.ctrlValuesHours(idxRow);
        break;
      case 'datePicker':
        // dateFormatted = moment(inputValue).format(this.dateFormat);
        if (inputValue !== null) {
          dateFormatted = CommonMethods.dateToString(inputValue, 'date', environment.fmtDateBdd);
          if (this.usageHistoInput[idxRow].inputStatut === 'exists') {
            if (this.usageHistoInput[idxRow].histoDate === dateFormatted) {
              return;
            }
          }
          this.usageHistoInput[idxRow].histoDate = dateFormatted;
          this.currentDateValue[idxRow] = inputValue;
          this.ctrlValuesDates(idxRow);
        }
        break; 
      case 'dateInput':
        if (inputValue !== null) {
        // dateFormatted = moment(inputValue).format(this.dateFormat);
          dateFormatted = CommonMethods.dateToString(inputValue, 'date', environment.fmtDateBdd);
          if (this.usageHistoInput[idxRow].inputStatut === 'exists') {
            if (this.usageHistoInput[idxRow].histoDate === dateFormatted) {
              return;
            }
          }
          this.usageHistoInput[idxRow].histoDate = dateFormatted;
          this.currentDateValue[idxRow] = inputValue;
          this.ctrlValuesDates(idxRow);
        }
        break;
      case 'histoRevision':
        if (this.usageHistoInput[idxRow].inputStatut === 'exists') {
          if (this.usageHistoInput[idxRow].histoRevision === inputValue) {
            return;
          }
        }
        this.usageHistoInput[idxRow].histoRevision = inputValue;
        const rowItem = this.histoUsageFa.at(idxRow) as FormGroup;
        // rowItem.controls.histoRevisionFg.setValue(inputValue);
        if (!this.usageHistoInput[idxRow].histoRevision) {
          rowItem.controls.taskrefIdFg.disable();
        } else {
          rowItem.controls.taskrefIdFg.enable();
        } 
        break;
      // revisionsTaskrefId
      case 'revisionsTaskrefId':
        if (this.usageHistoInput[idxRow].inputStatut === 'exists') {
          if (this.usageHistoInput[idxRow].taskrefId === inputValue) {
            return;
          }
        }
        this.usageHistoInput[idxRow].taskrefId = inputValue;
        this.usageHistoInput[idxRow].taskrefRow = 'histoInput';
        break;
    }
    if (this.usageHistoInput[idxRow].inputStatut === 'exists') {
      this.usageHistoInput[idxRow].inputStatut = 'update';
    }
    // this.ctrlInput();
  }
  ctrlValuesHours(idxRow: number): void {
    let messageDisplay: MessageDisplay;
    let hoursValue: number;
    let hoursPrevious: number;
    let hoursNext: number;

    hoursValue = this.usageHistoInput[idxRow].histoHours;

    if (idxRow > 0) {
      hoursPrevious = this.usageHistoInput[idxRow - 1 ].histoHours;
    }
    if (this.usageHistoInput[idxRow + 1] !== undefined) {
      hoursNext = this.usageHistoInput[idxRow + 1 ].histoHours;
    }
    if (hoursPrevious !== undefined && hoursPrevious >= hoursValue) {
      messageDisplay = {
        titleBox: 'Control values hours',
        messageLabel: 'hours lt',
        messageArgs: [this.usageHistoInput[idxRow].histoHours.toString(), this.usageHistoInput[idxRow - 1].histoHours.toString()],
        messageType: 'ERROR',
        messageDatas: undefined,
        typeDialog: 'alertWks',
        actionCur: 'validErrorHours',
        progressDeterminate: false,
      };
      this.usageHistoInput[idxRow].histoHours = undefined;
      const rowItem = this.histoUsageFa.at(idxRow) as FormGroup;
      rowItem.controls.hoursFg.setValue(undefined);
      this.messageBoxDisplay(messageDisplay);
    } else if (hoursNext !== undefined && hoursValue > hoursNext) {
        messageDisplay = {
          titleBox: 'Control values hours',
          messageLabel: 'hours gt',
          messageArgs: [this.usageHistoInput[idxRow].histoHours.toString(), this.usageHistoInput[idxRow + 1].histoHours.toString()],
          messageType: 'ERROR',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'validErrorHours',
          progressDeterminate: false,
        };
        this.usageHistoInput[idxRow].histoHours = undefined;
        const rowItem = this.histoUsageFa.at(idxRow) as FormGroup;
        rowItem.controls.hoursFg.setValue(undefined);
        this.messageBoxDisplay(messageDisplay); 
    }
  }
  ctrlValuesDates(idxRow: number): void {
    let messageDisplay: MessageDisplay;
    let dateValue: any ;
    let datePrevious: any ;
    let dateNext: any ;

    // dateValue = new Date(this.usageHistoInput[idxRow].histoDate);
    // dateValue =  moment(this.usageHistoInput[idxRow].histoDate, environment.fmtDateBdd);
    dateValue = this.currentDateValue[idxRow];
    if (idxRow  > 0 ) {
      // datePrevious = new Date(this.usageHistoInput[idxRow - 1 ].histoDate);
      // datePrevious =  moment(this.usageHistoInput[idxRow - 1 ].histoDate, environment.fmtDateBdd);
      datePrevious = this.currentDateValue[idxRow - 1];
    }
    if (this.usageHistoInput[idxRow + 1] !== undefined) {
      // dateNext = new Date(this.usageHistoInput[idxRow + 1 ].histoDate);
      // dateNext =  moment(this.usageHistoInput[idxRow + 1 ].histoDate, environment.fmtDateBdd);
      dateNext = this.currentDateValue[idxRow + 1];
    }
    if ( datePrevious !== undefined && dateValue <= datePrevious) {
    
      messageDisplay = {
        titleBox: 'Control values date',
        messageLabel: 'date lt',
        messageArgs: [this.usageHistoInput[idxRow].histoDate, this.usageHistoInput[idxRow - 1].histoDate],
        messageType: 'ERROR',
        messageDatas: undefined,
        typeDialog: 'alertWks',
        actionCur: 'validErrorDate',
        progressDeterminate: false,
      };
      this.usageHistoInput[idxRow].histoDate = undefined;
      const rowItem = this.histoUsageFa.at(idxRow) as FormGroup;
      rowItem.controls.dateFg.setValue(undefined);
      this.currentDateValue[idxRow] = undefined;
      this.messageBoxDisplay(messageDisplay); 
    }
    if ( dateNext !== undefined && dateValue >= dateNext) {
    
      messageDisplay = {
        titleBox: 'Control values date',
        messageLabel: 'date gt',
        messageArgs: [this.usageHistoInput[idxRow].histoDate, this.usageHistoInput[idxRow + 1].histoDate],
        messageType: 'ERROR',
        messageDatas: undefined,
        typeDialog: 'alertWks',
        actionCur: 'validErrorDate',
        progressDeterminate: false,
      };
      this.currentDateValue[idxRow] = undefined;
      this.usageHistoInput[idxRow].histoDate = undefined;
      const rowItem = this.histoUsageFa.at(idxRow) as FormGroup;
      rowItem.controls.dateFg.setValue(undefined);
      this.messageBoxDisplay(messageDisplay); 
    }
  }
  numberAndDotOnly(event: any): boolean {
    return CommonMethods.numberAndDotOnly(event);
  }
  remmoveRowUsageHours(idxHistoUsage: number): void {

    if (this.usageHistoCache[idxHistoUsage].id === undefined) {
      this.histoUsageFa.removeAt(idxHistoUsage);
      this.usageHistoCache.splice(idxHistoUsage, 1);
      this.usageHistoInput.splice(idxHistoUsage, 1);
      if (this.histoUsageFa.length === 0) {
        this.addRowUsageHours(-1);
      }
    } else {
      const rowItem = this.histoUsageFa.at(idxHistoUsage) as FormGroup;
      rowItem.controls.hoursFg.disable();
      rowItem.controls.dateFg.disable();
      this.usageHistoInput[idxHistoUsage].inputStatut = 'disable';
      rowItem.disable();
      // this.ctrlInput();
    }
  }
  addRowUsageHours(idxHistoUsage: number): void {
    if (idxHistoUsage === 0 || idxHistoUsage > 0) {
      if (this.usageHistoInput[idxHistoUsage].histoHours === undefined || 
          this.usageHistoInput[idxHistoUsage].histoDate === undefined) {
        return;
      }
    }

    this.histoUsageFa.insert(idxHistoUsage + 1, this.getEmptyRow());
    this.usageHistoCache.splice(idxHistoUsage + 1, 0 , this.getEmptyData());
    this.usageHistoInput.splice(idxHistoUsage + 1, 0 , this.getEmptyData());
    this.currentDateValue[idxHistoUsage + 1] = this.defaultDateRef;

  }
  getEmptyRow(): FormGroup {

    let newRow: FormGroup;
    newRow = this.newHistoUsageRow();
    newRow.controls.hoursFg.setValue(undefined);
    newRow.controls.dateFg.setValue(undefined);
    newRow.controls.histoRevisionFg.setValue(false);
    newRow.controls.taskrefIdFg.setValue(undefined);
    newRow.controls.taskrefIdFg.disable();
    return newRow;
  }
  getEmptyData(): WksEquipmentUsage {
    const histoUsageEmpty: WksEquipmentUsage = {
      id: undefined,
      equipId: this.equipIdCall,
      histoMonths: undefined,
      histoHours: undefined,
      histoDate: undefined,
      histoRevision: false,
      histoOtherdata: '',
      taskrefId: '',
      taskrefRow: '',
      stdEntity: this.workEquipArgs.stdEntity,
      stdCreation: undefined,
      stdLastupdate: undefined,
      stdDisable: undefined,
      inputStatut: 'create'
    };
    return histoUsageEmpty;
  }
  findInvalidControlsRecursive(formToInvestigate: FormGroup|FormArray): string[] {

    const invalidControls: string[] = [];
    const recursiveFunc = (form: FormGroup|FormArray) => {
      Object.keys(form.controls).forEach(field => { 
        const control = form.get(field);
        if (control.invalid)  {
          invalidControls.push(field);
        }
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        }        
      });
    };
    recursiveFunc(formToInvestigate);
    return invalidControls;
  }
  getHistoUsage(): void {
    this.isOkToDisplay = false;
    if (this._userService.getUserLogged() === undefined ) { return; }

    this._wksCentralService.getHistoUsage(this.workEquipArgs.stdEntity, this.equipIdCall)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        const response = data.body as WksEquipmentUsage[];
        response.sort((obj1, obj2) => {
          return obj1.stdCreation > obj2.stdCreation ? 1 : -1;
        });
        this.fillForm(response);
      },
      _err => {
        // console.log(err);
        const messageDisplay2: MessageDisplay =  {
          titleBox: 'usage hours',
          messageLabel: 'no hours',
          messageArgs: [],
          messageType: 'ERROR',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'validErrorReading',
          progressDeterminate: false,
        };
        this.messageBoxDisplay(messageDisplay2); 
        
      }
    );
  }
  fillForm(usageHistoListArg: WksEquipmentUsage[]): void {
    
    this.usageHistoCache = [];
    this.usageHistoInput = [];
    this.currentDateValue = [];
    this.buildForm();
    let newRow: FormGroup;
    if (usageHistoListArg.length === 0 ) {
      this.addRowUsageHours(-1);
      this.isOkToDisplay = true;
      return;
    }
    for (let idxRow = 0; idxRow < usageHistoListArg.length; idxRow++) {
      this.affectCache(usageHistoListArg[idxRow]);
      this.currentDateValue[idxRow] = moment(usageHistoListArg[idxRow].histoDate, environment.fmtDateBdd).toDate();
      newRow = this.newHistoUsageRow();
      newRow.controls.hoursFg.setValue(usageHistoListArg[idxRow].histoHours);
      newRow.controls.dateFg.setValue(this.currentDateValue[idxRow]);
      newRow.controls.histoRevisionFg.setValue(usageHistoListArg[idxRow].histoRevision ? true : false);
      newRow.controls.taskrefIdFg.setValue(usageHistoListArg[idxRow].taskrefId);
      this.histoUsageFa.push(newRow);
      // this.currentDateValue[idxRow] = this.usageHistoInput[idxRow].histoDate ;
    }
    this.usageHistoInput = JSON.parse(JSON.stringify(this.usageHistoCache));
    this.isOkToDisplay = true;
  }
  affectCache(histoUsage: WksEquipmentUsage): void {

    const histoUsageTmp: WksEquipmentUsage = histoUsage;
    histoUsageTmp.inputStatut = 'exists';
    this.usageHistoCache.push(JSON.parse(JSON.stringify(histoUsageTmp)));
    // this.usageHistoInput.push(JSON.parse(JSON.stringify(histoUsageTmp)));

  }
  ctrlInput(): void {
    this.isOkToSave = false;
    if (this.histoUsageForm.status === 'INVALID') {
      return;
    } else {
      const usageHistoCurrent = this.fillModel();
      if (JSON.stringify(this.usageHistoCache) !== JSON.stringify(usageHistoCurrent)) {
        const formContent = this.findInvalidControlsRecursive(this.histoUsageForm);
        if (formContent.length === 0) {
          this.usageHistoInput = usageHistoCurrent;
          this.isOkToSave = true;

        }
      }
    }
  }
  fillModel(): WksEquipmentUsage[] {
    
    const nbRows = this.histoUsageFa.length;
    const histoUsage: WksEquipmentUsage[] = [];
    for (let idxRow = 0; idxRow < nbRows; idxRow++) {
      const rowItem = this.histoUsageFa.at(idxRow) as FormGroup;
      let usageHistoCur:  WksEquipmentUsage; 
      try {
        usageHistoCur = this.usageHistoInput[idxRow];
      } catch (error) {
        continue;
      } finally { }
      const histoUsageInput: WksEquipmentUsage =  {
        id: usageHistoCur !== undefined && usageHistoCur.id ? usageHistoCur.id : '',
        equipId: this.equipIdCall,
        histoMonths: 0,
        histoHours: Number(rowItem.controls.hoursFg.value),
        histoDate: usageHistoCur.histoDate,
        histoOtherdata: usageHistoCur !== undefined && usageHistoCur.histoOtherdata ? usageHistoCur.histoOtherdata : null,
        taskrefId: usageHistoCur.taskrefId,
        taskrefRow: usageHistoCur.taskrefRow,
        histoRevision: usageHistoCur.histoRevision,
        stdEntity: this.workEquipArgs.stdEntity,
        stdCreation: usageHistoCur !== undefined && usageHistoCur.stdCreation ? usageHistoCur.stdCreation : null,
        stdLastupdate: usageHistoCur !== undefined && usageHistoCur.stdLastupdate ? usageHistoCur.stdLastupdate : null,
        stdDisable: usageHistoCur !== undefined && usageHistoCur.stdDisable ? usageHistoCur.stdDisable : null,
        inputStatut: usageHistoCur !== undefined && usageHistoCur.inputStatut ? usageHistoCur.inputStatut : 'unknow',
      };
      
      histoUsage.push(histoUsageInput);
    }

    return histoUsage;
  }

  registerUsageHours() {
    // this.isOkToDisplay = false;
    const usageHours = this.fillModel();
    this._wksCentralService.saveHistoUsage(usageHours, 'update')
    .subscribe(
      usageHoursReturn => {
        const response = usageHoursReturn.body as WksEquipmentUsage[];
        response.sort((obj1, obj2) => {
          return obj1.stdCreation > obj2.stdCreation ? 1 : -1;
        });
        this.fillForm(response);
        this.isOkToSave = false;
      },
      () => {

        const messageDisplay2: MessageDisplay =  {
          titleBox: 'usage hours',
          messageLabel: 'register error',
          messageArgs: [],
          messageType: 'ERROR',
          messageDatas: undefined,
          typeDialog: 'alertWks',
          actionCur: 'validErrorRegister',
          progressDeterminate: false,
        };

        this.messageBoxDisplay(messageDisplay2);
      }
    );
  }
// ========================================================== errors alerts 
messageBoxDisplay(_messageDisplay: MessageDisplay) {

  const dialogConfig = new MatDialogConfig();
  const messageBox = this._translate.instant(_messageDisplay.messageLabel, _messageDisplay.messageArgs );
  
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data = {
    id: 1,
    title: this._translate.getTranslate( _messageDisplay.titleBox),
    typeDialog: _messageDisplay.typeDialog,
    panelClass: 'stdTheme',
    contentMessage: messageBox,
    data1: _messageDisplay.messageDatas,
    data2: '',
    actionCur: _messageDisplay.actionCur,
    progressDeterminate: _messageDisplay.progressDeterminate,
    errorsList: undefined,
    messageType: _messageDisplay.messageType
  };
  this.dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);
  
  this.dialogRef.afterClosed()
  .pipe(takeUntil(this.onDestroy))
  .subscribe(
    data => {
      if (( data === 'okAction') && 
        ( _messageDisplay.actionCur === 'validSpecification')) {
          this.endProcessFile();

      } else if (( data === 'okAction') && 
        ( _messageDisplay.actionCur === 'validErrorRegister')) {
          this.endProcessFile();
      } else if (( data === 'okAction') && 
        ( _messageDisplay.actionCur === 'validErrorReading')) {
          this.endProcessFile();
          this.fillForm([]);
      } else {
        // this.specjobsInput = JSON.parse(JSON.stringify(this.specjobsUpdate)); 
        this.endProcessFile();
      } 
    });

}
closeMatDialog(): void {
  this._dialog.closeAll();

  // console.log(this._dialog.openDialogs.length);
  if (this._dialog.openDialogs.length > 0) {

    for (const instCur of this._dialog.openDialogs) {
      this._dialog.openDialogs.splice(0, 1);
    }
  }
  this.dialogRef = null;
}
private endProcessFile() {
  this.closeMatDialog();

}
}
