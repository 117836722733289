<div #refElement *ngIf="isLoaded" class="mat-elevation-z8 container-fluid stdTheme" style="overflow-x: hidden; overflow-y: hidden;"
    [ngStyle]="{'margin-top': '20px', width: '100%' ,padding: '10px'}" >
    <div class="flex-row row stdTheme" style="margin: 0px"  draggable="true" #dropArea style="overflow-x: hidden; overflow-y: hidden;">
        <div [class]="taskrefCol"  style="padding-right: 5px; padding-left: 5px;overflow-x: hidden; overflow-y: hidden;">
            <form [formGroup]="tasksRefForm"  >
                <!-- https://stackblitz.com/edit/angular-material-elevation-hover?file=src%2Fapp%2Fapp.component.html -->
                <mat-card appMaterialElevation class="register-card stdTheme" layout="column" flex 
                            style="padding: 0px; padding-right: 5px; padding-left: 5px;overflow-x: hidden; overflow-y: hidden;">
                    <mat-card-header *ngIf="parentTask.statutCall !==  'accordion'">
                        <mat-card-title>
                            <section class="mat-typography">
                                <h1 class="titleTheme" style="padding-top: 10px;">{{ titleCard | translate }}</h1>
                            </section>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-actions style="margin-left: 0px;margin-right: 0px;">
                        <div class="container-fluid d-flex justify-content-between" >
                            <button mat-raised-button  #buttonSubmit type="reset" (click)="doCancel()">
                                <mat-icon>arrow_back</mat-icon>
                                {{ 'goback' | translate }}
                            </button>
                            <button *ngIf="(!openedAccordion) && ((!isRegistered && tasksRefForm.valid) || (tasksRefForm.valid && tasksRefForm.touched)) " mat-raised-button  #buttonSubmit type="submit" (click)="registerTaskRef('main')" >
                                <mat-icon>details</mat-icon>
                                {{ 'detail' | translate }}
                            </button>
                        
                            <button *ngIf="(openedAccordion) && ((!isRegistered && tasksRefForm.valid && !inputFailed) || (tasksRefForm.valid && tasksRefForm.touched)) " mat-raised-button  #buttonSubmit type="submit" (click)="registerTaskRef('main&detail')" >
                                <mat-icon>save</mat-icon>
                                {{ 'save' | translate }}
                            </button>
                        </div>
                    </mat-card-actions>
                    <mat-card-content *ngIf="okToDisplayForm" class="stdTheme" 
                            style="overflow-x: hidden!important; overflow-y: auto!important;height: 600px!important">
                        <div  class="flex-row row d-flex align-items-center" style="margin-left: 0px;margin-right: 0px;"> 
                            <div  class="col-5" style="margin-bottom: 10px;">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'workCode' | translate }}</mat-label>
                                    <mat-select id="workCode" placeholder="{{ 'Choose workCode' | translate }}" formControlName="workCode" 
                                            panelClass="mdiselectcontent"   (ngModelChange)="onChangeJobRef($event)"
                                            ngDefaultControl required >
                                        <mat-option class="selectOptionsTheme" *ngFor="let workCodeCur of workCodeList" 
                                            [value]="workCodeCur.value"  >{{workCodeCur.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>   
                            <div  class="col-2" style="margin-bottom: 10px;">
                                
                            </div>
                            <div  class="col-5 d-flex justify-content-center" style="margin-bottom: 10px;">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label >{{ 'jobPud' | translate }}</mat-label>
                                    <input id="jobPud" class="form-control inputTheme" formControlName="jobPud" 
                                        readonly matInput #jobPud placeholder="{{ 'jobPud' | translate }}">
                                </mat-form-field>
                            </div>
                        </div>    
                        <div  class="flex-row row d-flex align-items-center" style="margin-left: 0px;margin-right: 0px;">          
                            <div class="col-12">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label *ngIf="isParamTaskRef">{{ 'taskrefLabel' | translate }}</mat-label>
                                    <mat-label *ngIf="!isParamTaskRef && isFourniture">{{ 'fournitureLabel' | translate }}</mat-label>
                                    <mat-label *ngIf="!isParamTaskRef && !isFourniture">{{ 'jobLabel' | translate }}</mat-label>
                                    <input id="taskrefLabel" class="form-control inputTheme" formControlName="taskrefLabel" readonly matInput #input placeholder="{{ 'taskrefLabel' | translate }}"
                                    required >
                                </mat-form-field>
                            </div>    
                        </div>
                        <div class="flex-row row d-flex align-items-center" style="margin-left: 0px;margin-right: 0px;">
                            <div class="col-12" style="margin-top: 10px">
                                <div *ngFor= "let langCur of langLabelsList; let rowLang=index; let isLast=last" >
                                    <table class="addressTable table table-bordered table-striped" border="0" cellpadding="0" cellspacing="0">
                                        <tbody class="stdTheme" style="overflow-y: auto;  height: 15%;" >
                                            <tr  >
                                                <td class="stdTheme" style="width: 10%; padding: 2px; padding-left: 5px; padding-right: 5px;">
                                                    {{langCur.langDisplay}}
                                                </td>
                                                <td class="stdTheme" style="width: 90%; padding: 2px; padding-left: 5px; padding-right: 5px;">

                                                    <input type="text" style="width: 100%" class="inputNormal" #langLabel name="langLabel" value="{{langCur.langLabel}}"
                                                                    (keyup)="setLangLabelValue(langLabel.value,rowLang)" [direction]="langCur.langDirection"/>
                                                </td>
                                            </tr>
                                        </tbody>    
                                    </table>
                                </div>                                      
                            </div>
                        </div>
                        <div *ngIf="isParamTaskRef" class="flex-row row d-flex align-items-center" style="margin-left: 0px;margin-right: 0px;">
                            <div class="col-4" style="margin-bottom: 10px;">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'equipType' | translate }}</mat-label>
                                    <mat-select id="equipType" placeholder="{{ 'Choose equipType' | translate }}" formControlName="equipType" 
                                            panelClass="mdiselectcontent"   ngDefaultControl required >
                                        <mat-option class="selectOptionsTheme" *ngFor="let equipTypeCur of equipTypeList" 
                                            [value]="equipTypeCur.value"  >{{equipTypeCur.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div> 
                            <div class="col-4">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'equipBrand' | translate }}</mat-label>
                                    <mat-select id="equipBrand" placeholder="{{ 'Choose brand' | translate }}" formControlName="equipBrand" 
                                                panelClass="mdiselectcontent" #equipBrand
                                                (ngModelChange)="onChangeBrand($event)"
                                                ngDefaultControl >
                                        <mat-select-header>
                                            <div style="display: inline-block;" >
                                                <button mat-icon-button matPrefix aria-label="search" >
                                                    <mat-icon>search</mat-icon>
                                                </button>
                                                <input class="searchTheme" #filterbrand placeholder="{{ 'filterInput' | translate }}" 
                                                        (keyup)="onKeyBrand($event)" >
                                                <button mat-icon-button matSuffix aria-label="clear" 
                                                        *ngIf="equipBrand.value" (click)="equipBrand.value=''; onKeyBrand('');">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                                <button mat-icon-button matSuffix aria-label="add" 
                                                    *ngIf="brandFilteredList.length === 0" (click)="addBrand(filterbrand.value)">
                                                    <mat-icon>add</mat-icon>
                                                </button>
                                            </div>
                                        </mat-select-header>
                                        <mat-option class="selectOptionsTheme" *ngFor="let brandCur of brandFilteredList" 
                                            [value]="brandCur.brandName"  >{{brandCur.brandName}}
                                        </mat-option>
                                    </mat-select>                         
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'equipModel' | translate }}</mat-label>
                                    <mat-select id="equipModel" placeholder="{{ 'Choose model' | translate }}" formControlName="equipModel" 
                                            panelClass="mdiselectcontent"
                                            (ngModelChange)="onChangeModel($event)"
                                            ngDefaultControl >
                                        <mat-select-header>
                                            <div style="display: inline-block;" >
                                                <button mat-icon-button matPrefix aria-label="search" >
                                                    <mat-icon>search</mat-icon>
                                                </button>
                                                <input class="searchTheme" #filtermodel placeholder="{{ 'filterInput' | translate }}" 
                                                        (keyup)="onKeyModel($event)" >
                                                <button mat-icon-button matSuffix aria-label="clear" 
                                                        *ngIf="filtermodel.value" (click)="filtermodel.value=''; onKeyModel('');">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                                <button mat-icon-button matSuffix aria-label="add" 
                                                    *ngIf="modelFilteredList.length === 0" (click)="addModel(filtermodel.value)">
                                                    <mat-icon>add</mat-icon>
                                                </button>
                                            </div>
                                        </mat-select-header>
                                        <mat-option class="selectOptionsTheme" *ngFor="let modelCur of modelFilteredList" 
                                            [value]="modelCur.modelName"  >{{modelCur.modelName}}
                                        </mat-option>
                                    </mat-select>    
                                </mat-form-field>
                            </div>   
                        </div>
                        <!-- start 2023-11-13 -->
                        <div class="flex-row row d-flex align-items-center" style="margin: 0px">
                            <div class="col-4" style="margin-top: 15px">
                                <mat-checkbox #revision formControlName="revision"  
                                    [labelPosition]="after"
                                    (change)="onInputChange('revision',revision.checked)"
                                    [checked]="isRevision">
                                        <div class="text-wrap">
                                            {{ 'revision' | translate }}
                                        </div>
                                </mat-checkbox> 
                            </div>
                        </div>
                        <div *ngIf="isRevision" class="flex-row row d-flex align-items-center" style="margin-left: 0px;margin-right: 0px;margin-bottom: 10px">

                            <div class="col-6 d-flex justify-content-start" >
                                <span style="margin-top: 35px;margin-right: 15px;">
                                    {{ 'frequency' | translate }}
                                </span>
                                 <mat-form-field class="wksInput form-group" appearance="standard" style="margin-right: 15px;width: 30%" >
                                    <mat-label>{{ 'periodicityHours' | translate }}</mat-label>

                                    <mat-select id="periodicityHours" 
                                            panelClass="mdiselectcontent" #periodicityHours required
                                            formControlName="periodicityHours"
                                            [ngStyle]="{'text-align': numberFormat.justifText}"
                                            (selectionChange)="onInputChange('periodicityHours',periodicityHours.value)"
                                            [value]="jobManufacturerAtt.hours"
                                            ngDefaultControl >
                                        <mat-option class="selectOptionsTheme" *ngFor="let hourCur of hoursList" 
                                            [value]="hourCur.value"  >{{hourCur.value}}
                                        </mat-option>
                                    
                                    </mat-select> 
                                </mat-form-field>

                                <mat-form-field class="wksInput form-group" appearance="standard" style="width: 30%">
                                    <mat-label>{{ 'periodicityMonths' | translate }}</mat-label>
                                    <mat-select id="periodicityMonths" 
                                            panelClass="mdiselectcontent" #periodicityMonths required
                                            formControlName="periodicityMonths"
                                            [ngStyle]="{'text-align': numberFormat.justifText}"
                                            (selectionChange)="onInputChange('periodicityMonths',periodicityMonths.value)"
                                            [value]="jobManufacturerAtt.months"
                                            ngDefaultControl >
                                        <mat-option class="selectOptionsTheme" *ngFor="let monthCur of monthsList" 
                                            [value]="monthCur.value"  >{{monthCur.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </div>
                        <!--</div>
                        <div  class="flex-row row d-flex align-items-center" style="margin-left: 0px;margin-right: 0px;">-->
                            <div class="col-6 d-flex justify-content-start" >
                                <span style="margin-top: 35px;margin-left: 0px;">
                                    {{ 'jobLevel' | translate }}
                                </span>

                                <mat-radio-group class="level-radio-group"  #jobLevel="matRadioGroup" labelPosition="after"
                                        style="margin-top: 0px;margin-bottom: 0px; padding-left: 5px;"
                                        formControlName="jobLevel"
                                        [value]="jobLevel"
                                        (change)="onInputChange('jobLevel',jobLevel.value)">
                                    <mat-radio-button class="radioButton" style="padding-right: 15px;margin-top: 35px;" 
                                            [checked]="!jobManufacturerAtt.jobMajor"
                                            value="{{levelOptions.normal}}">{{ 'jobNormal' | translate }}
                                    </mat-radio-button>
                                    <mat-radio-button class="radioButton" style="margin-top: 35px;" 
                                            [checked]="jobManufacturerAtt.jobMajor"
                                            value="{{levelOptions.major}}">{{ 'jobMajor' | translate }}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                        <!-- end 2023-11-13-->
                        <div  class="flex-row row d-flex align-items-center" style="margin-left: 0px;margin-right: 0px;">
                            <div class="col-12">
                                <fieldset class="sn-border" >
                                    <legend class="sn-border" align="center" ><b>{{ 'serial numbers' | translate }}</b></legend>
                                    <div  class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                                        <div class="col-4" style="margin-top: 0px; margin-left: 0px;margin-right: 0px;">
                                            <mat-checkbox color="warn" #allSNCheck formControlName="allSNCheck"  
                                                [labelPosition]="before"
                                                (change)='showAllSN(allSNCheck.checked)' 
                                                [checked]='allSNChecked'>
                                                    <div class="text-wrap">
                                                        {{ 'allSN' | translate }}
                                                    </div>
                                            </mat-checkbox> 
                                        </div>
                                    </div>
                                    <div  class="flex-row row" *ngIf="!allSNChecked" style="margin-left: 0px;margin-right: 0px;">
                                        <ng-container  
                                            [ngTemplateOutlet]="taskSNTemplate">
                                        </ng-container>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div *ngIf="isParamTaskRef && tasksrefListCur.length > 0" class="flex-row row" 
                                        style="margin-left: 0px;margin-right: 0px;height: 70px; margin-bottom: 0px;">
                            <div class="col-3" style="margin-top: 15px;padding-left: 15px;padding-right: 15px;">
                                <mat-checkbox color="warn" #associatedTaskCheck formControlName="associatedTaskCheck"  
                                    [labelPosition]="before"
                                    (change)='showAssociatedTask(associatedTaskCheck.checked)' 
                                    [checked]='associatedTaskChecked'>
                                        <div class="text-wrap">
                                            {{ 'associated_task' | translate }}
                                        </div>
                                </mat-checkbox> 
                            </div>
                            <div *ngIf="associatedTaskCheck.checked" class="col-9" style="height :50px">
                                <mdi-taskref-parent
                                [tasksrefList]= "tasksrefListCur"
                                [taskrefParentCur]= "taskrefParentCur"
                                [childRestrictSN]= "restrictSN"
                                (dataOut)="dataOutParent($event)"
                                >
                                </mdi-taskref-parent>
                            </div>
                        </div>
                        <div *ngIf="!isParamTaskRef" class="flex-row row d-flex align-items-center" style="margin-left: 0px;margin-right: 0px;">          
                            <div *ngIf="isFourniture" class="col-5">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'partRef' | translate }}</mat-label>
                                    <input id="partRef" class="form-control inputTheme" formControlName="partRef" maxlength="25" matInput #input 
                                                    placeholder="{{ 'partRef' | translate }}"
                                                    (keyup)="onKeyPartRef($event.target.value)"
                                     >
                                </mat-form-field>
                            </div>  
                            <div *ngIf="isFourniture" class="col-3" style="margin-bottom: 10px;">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'taskrefUnit' | translate }}</mat-label>
                                    <mat-select id="taskrefUnit" placeholder="{{ 'Choose taskrefUnit' | translate }}" formControlName="taskrefUnit" 
                                            panelClass="mdiselectcontent"   ngDefaultControl required >
                                        <mat-option class="selectOptionsTheme" *ngFor="let taskrefUnitCur of unitsQuantity" 
                                            [value]="taskrefUnitCur.value"  >{{taskrefUnitCur.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div> 
                            <div *ngIf="isFourniture" class="col-3" style="margin-bottom: 10px;">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'taskrefQuantity' | translate }}</mat-label>
                                    <input  class="form-control inputTheme" #taskrefQuantity name="taskrefQuantity" formControlName="taskrefQuantity" 
                                     matInput
                                    onblur="setTaskrefQuantityValue(taskrefQuantity.value)"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="!isParamTaskRef" class="flex-row row d-flex align-items-center" style="margin-left: 0px;margin-right: 0px;">          

                            <div *ngIf="!isFourniture" class="col-3" style="margin-bottom: 10px;">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'taskrefUnit' | translate }}</mat-label>
                                    <mat-select id="taskrefUnit" placeholder="{{ 'Choose taskrefUnit' | translate }}" formControlName="taskrefUnit" 
                                            panelClass="mdiselectcontent"   ngDefaultControl required >
                                        <mat-option class="selectOptionsTheme" *ngFor="let taskrefUnitCur of billingUnitsList" 
                                            [value]="taskrefUnitCur.value"  >{{taskrefUnitCur.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div> 
                            <div *ngIf="!isFourniture" class="col-3" style="margin-bottom: 10px;">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'taskrefDuration' | translate }}</mat-label>
                                    <input  class="form-control inputTheme" #taskrefQuantity name="taskrefQuantity" formControlName="taskrefQuantity" 
                                     matInput
                                    onblur="setTaskrefQuantityValue(taskrefQuantity.value)"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                        <div  class="flex-row row" style="margin-left: 0px;margin-right: 0px;">
                            <div class="col-12" style="margin-bottom: 10px;padding-left: 15px;padding-right: 15px;">
                                <mat-form-field class="mdiinput form-group" appearance="standard">
                                    <mat-label>{{ 'taskrefComment' | translate }}</mat-label>
                                    <textarea  id="taskrefComment" class="form-control inputTheme textarea1" formControlName="taskrefComment" 
                                                matInput #taskrefComment placeholder="{{ 'taskrefComment' | translate }}"
                                                cdkTextareaAutosize
                                                #autosize="cdkTextareaAutosize"
                                                cdkAutosizeMinRows="1"
                                                cdkAutosizeMaxRows="4"
                                                (keyup)="onChangeComment(taskrefComment.value)"
                                    ></textarea >
                                </mat-form-field>
                            </div>
                        </div>  
                    </mat-card-content>
                    <mat-card-actions *ngIf="!isParamTaskRef && actionType === 'update'">
                        <div class="container-fluid d-flex justify-content-center" style="margin-bottom: 20px;">
                            <button  mat-raised-button  #buttonSubmit type="reset" (click)="removeTaskRef()">
                                <mat-icon>delete_sweep</mat-icon>
                                {{ 'Delete' | translate }}
                            </button>
                        </div>
                    </mat-card-actions>
                </mat-card>
            </form>
        </div>
        <div *ngIf="openedAccordion && parentTask.statutCall !==  'accordion'" class="col-8">
            <span>
                <h4 class="titleTheme" style="padding-top: 10px;">{{ 'check list' | translate }}</h4>
            </span>
            <mdi-tasksref-accordion style="width: 100%;" 
                        [tasksActionsContent]="tasksActionsContent"
                        [tasksActionsParentContent]="tasksActionsParentContent"
                        [parentTask] = "parentTask"
                        [wksEntity]="wksEntity"
                        [actionType]="'taskdef'"
                        [rowTask]="rowTask"
                        (dataOut)="statutCur($event)"></mdi-tasksref-accordion>
        </div>
    </div>
</div>
<ng-template let-value="value" #taskSNTemplate>
    <div  class="flex-row row stdTheme" style="margin-left: 0px;margin-right: 0px;width: 100%">
        <div class="col-6 d-flex justify-content-center">{{'snFrom' | translate}}</div>
        <div class="col-6 d-flex justify-content-center">{{'snUpto' | translate}}</div>
    </div>
    <div class="stdTheme" *ngFor="let taskrefSNItem of taskrefSN; let rowItem = index" >
        <div  class="flex-row row stdTheme" style="margin-left: 0px;margin-right: 0px;width: 100%">
            <div class="col-1 d-flex justify-content">
                <button mat-icon-button (click)="addTaskRefSN(rowItem)" >
                    <span class="fa fa-plus" style="font-size: 10px" aria-hidden="true"></span>
                </button>
            </div>
            <div class="col-5 d-flex justify-content-start" style="padding-right: 20px;">
                <input class="form-control inputTheme" name="snFrom" #snFrom  
                        value="{{taskrefSNItem.snFrom}}"
                        matInput (change)="setTaskSNValues('snFrom',snFrom.value, rowItem)"
                />
            </div>
            <div class="col-5 d-flex justify-content-start" style="padding-right: 20px;">
                <input class="form-control inputTheme" name="snUpto" #snUpto  
                        value="{{taskrefSNItem.snUpto}}"
                        matInput (change)="setTaskSNValues('snUpto',snUpto.value, rowItem)"
                />
            </div>
            <div class="col-1 d-flex justify-content">
                <button mat-icon-button (click)="removeTaskRefSN(rowItem)" >
                    <span class="fa fa-minus" style="font-size: 10px" aria-hidden="true"></span>
                </button>
            </div>
        </div>
    </div>
</ng-template>