import { Component, OnInit, ViewChild, TemplateRef, ViewEncapsulation, Input, NgZone } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatDialog, MatDialogConfig, MatSnackBar } from '@angular/material';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject, Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';

import { GridOptions } from 'ag-grid-community';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonMethods } from '../../../../../common/tools/commonMethods';
import { GridTools } from '../../../../../common/tools/gridTools';
import { ModalCommonComponent } from '../../../../../common/components/modal-common/modal-common.component';

import { GlobalEventsManagerService } from '../../../../../common/services/global-events-manager.service';
import { WksCentralService } from '../../../../services/wks-central.service';
import { NotificationsService } from '../../../../../common/services/notifications.service';
import { TranslateService } from '../../../../../../services/translate.service';
import { UserService } from '../../../../../../services/user.service';
import { WksWorksService } from '../../../../services/wks-works.service';

import { WksWorksModel, wksworks_sch, DataWorkModel, WorkEquipmentRef  } from '../../../../models/wks-works.model';
import { WorkStatutModel } from '../../../../models/wks-param.model';

import { CriticalityRendererComponent } from './renderers/criticality-renderer/criticality-renderer.component';
import { AvailableRendererComponent } from './renderers/available-renderer/available-renderer.component';
import { FileToDisplay, NotifUsers } from '../../../../../../models/common.model';

// WorksChangeStatutComponent
import { WorksChangeStatutComponent } from '../works-change-statut/works-change-statut.component';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'mdi-works-table',
  templateUrl: './works-table.component.html',
  styleUrls: ['./works-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WorksTableComponent implements OnInit {

  @Input() workEquipArgs: WorkEquipmentRef;
  @Input() optionsArg: any;


  @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;
  @ViewChild('agGrid', {static: true}) agGrid: GridOptions; 

  modalRef: BsModalRef;
  dataInWork: DataWorkModel;
  
  valueWidth = false;
  displayType: string;
  dataIn: any;
  optionItem: string;
  filesToDisplay: FileToDisplay[];
  doUpdateWork: any;
  sidenavCollapsed: boolean;
  hasBackdrop: boolean;
  workStatuts: WorkStatutModel [];
  workStatutsList: WorkStatutModel [];
  dataSource = new MatTableDataSource();
  private readonly onDestroy = new Subject<void>();

  displayedColumns: any;
  dataColumnsName: any;
  dataColumnsDef = wksworks_sch;
  dataColumns: any;
  dateFormat: string;
  timeFormat: string;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  loadingIndicator = true;
  cacheDatatemp = [];

  displayWorkCard: boolean;

  workToUpdate: WksWorksModel;
  userLangCur: string;
  reorderable = true;
  selected = [];
  rows = [];

  gridOptions: any;
  gridApi: any;
  frameworkComponents: any;
  gridColumnApi: any;
  defaultColDef: any;
  autoGroupColumnDef: any;
  rowGroupPanelShow: any;
  localeText: any;
  statutSelected: string;
  actionType: string;
  notificationsSubscription: Subscription;
  
  constructor(private _userService: UserService,
    private _currentRoute: ActivatedRoute,
    private _translate: TranslateService,
    private _wksCentralService: WksCentralService,
    private modalService: BsModalService,
    private _notificationsService: NotificationsService,
    private snackBar: MatSnackBar,
    private _zone: NgZone,
    private _globalEventsManagerService: GlobalEventsManagerService,
    private _wksWorksService: WksWorksService,
    private _dialog: MatDialog,
    private fb: FormBuilder) {
      this._translate.onLangChanged.subscribe(() => {
        this.translateLabels();
      });
  }

  ngOnInit() {
    if (this._userService.getUserLogged() === undefined ) { return; }
    // let labelItem = this._currentRoute.snapshot.data[0]['itemOption'];
    this._currentRoute.paramMap.subscribe(params => {
      this.displayType = params.get('display');
    });
    if (this.displayType === null) {
      this.displayType = 'list';
    }
    this.optionItem = this._currentRoute.snapshot.data[0]['itemOption'];
    this.sidenavCollapsed = true;
    this.hasBackdrop = true;
    this.subscribeAll(); 
    this.dateFormat = this._translate.getLocalFmt('dateFormat');
    this.timeFormat = this._translate.getLocalFmt('timeFormat');
    if (this.displayType === 'list') {
      this.buildList();
    }
    this.buildStatutsList();
  }
  buildStatutsList(): void  {
    this.workStatuts = this._wksCentralService.getWorkStatuts();
    this.workStatutsList = [...this.workStatuts];
    let idxStatut = 0;
    let idxUndefined = -1;
    for (const statut of this.workStatutsList) {
      if (statut.label === 'undefined') {
        idxUndefined = idxStatut;
        continue;
      }
      idxStatut++;
      statut.labelDisplay = this._translate.instant(statut.label);
    }
    if (idxUndefined > -1) {
      this.workStatutsList.splice(idxUndefined, 1);
    }
    this.workStatutsList.sort((obj1: WorkStatutModel, obj2: WorkStatutModel) => {
      return obj1.labelDisplay > obj2.labelDisplay ? 1 : -1;
    });
    const allStatut: WorkStatutModel = {
      number: -1,
      label: 'ALL',
      tooltip: '',
      processtodo: '',
      next: -2,
      command: '',
      commentRequired: false,
      ihmOptions: undefined,
      labelDisplay: this._translate.instant('All statuts')
    };
    this.statutSelected = 'ALL';
    this.workStatutsList.splice(0, 1, allStatut);
  }
  connectSnackBar(): void {

    this.notificationsSubscription = this._notificationsService.connectNotif('appointments')
    .pipe(takeUntil(this.onDestroy))
    .subscribe({
      next: notif => {
        this._zone.run(() => {
          const msgData: NotifUsers = JSON.parse(notif);
          // console.log(msgData.recipients, ' ' + msgData.contentMessage);
          if (msgData.recipients === this._userService.getUserLogged().entity) {
            const workMsg: WksWorksModel = JSON.parse(msgData.contentMessage);
            if (this.statutSelected === 'ALL' || this.statutSelected === 'appointment') {
              // this.rows.unshift(appointmentCur);
              this.dataSource.data.splice(0, 0, workMsg);
              this.rows = [...this.rows];
              setTimeout(() => {
                // this.ngxDatatable.element.click();
               }, 500);
            }
            const messageCur = this._translate.getTranslate('New appointment') + ' : ' + workMsg.workRef;
            this.openSnackBar(messageCur, 'No-Action');
          }
        });
      }
    });
  }
  subscribeAll() {
    this._globalEventsManagerService.synchroEvents
    .pipe(takeUntil(this.onDestroy))
    .subscribe( event => {
      // 'validateQuoteModal
      let eventString: string;
      const typeEvent = typeof event;
      if (typeEvent === 'string')  {
        eventString = String(event);
        if (eventString !== undefined) {

          if (eventString === 'closeWorkModal') {
            this.closeModal();
          } else if (eventString === 'cancelModal') {
            // console.log('fermeture modal ');
            this.closeModal();
          } else if (eventString === 'cancelWork') {
            // console.log('fermeture sidenav');
            this.sidenavCollapsed = false;
            this.toggleSidenav('');
          }
        }
      } else if (event) {
        if (event.workevent) {
          const rowNode = this.gridApi.getRowNode(event.iRow);
          const workStatutLabel = this._translate.instant(event.workStatut);
          rowNode.setDataValue('workStatutLabel', workStatutLabel);
        } else  {
          console.log(JSON.stringify(typeEvent));
        }
      }
    });
    this._wksCentralService.onRegisterValid
    .pipe(takeUntil(this.onDestroy))
    .subscribe((event: any) => {
      if (this.optionsArg === 'equipmentSelect') {
        return;
      } else if (event === 'cancelRegister') {
        this.toggleSidenav('');
      } else if (event === 'closeRegister')  {
        this.toggleSidenav('');
        this.buildList();
      }
      /*
      if (event === 'deleteWork') {
        this.buildList();
      }
      */
    });
  }
  buildList() {
    this.frameworkComponents = { 
      criticalityRenderer: CriticalityRendererComponent,
      availableRenderer: AvailableRendererComponent,
    };
    this.displayedColumns = [
      { field: 'workRef', headerName: this._translate.instant('workRef'), sortable: true, filter: true,  width: 100,
          minWidth: 100, maxWidth: 100, headerTooltip: '', headerClass: 'text-center' },
      { field: 'workCustomerName', headerName: this._translate.instant('workCustomerName'), sortable: true, filter: true,  
          resizable: true, width: 200,
          minWidth: 200, maxWidth: 200, headerTooltip: '', headerClass: 'text-center' },
      { field: 'workMainEquipName', headerName: this._translate.instant('workMainEquipName'), sortable: true, filter: true,  
      resizable: true, width: 200,
          minWidth: 200, maxWidth: 200, headerTooltip: '', headerClass: 'text-center' },
      { field: 'workObject', headerName: this._translate.instant('workObject'), sortable: true, filter: true, resizable: true, 
          width: 200, minWidth: 200, maxWidth: 200,
          headerTooltip: '', headerClass: 'text-center' },
      { field: 'workStatutLabel', headerName: this._translate.instant('workStatut'), sortable: true, filter: true, width: 100,
          minWidth: 100, maxWidth: 100, headerTooltip: '', headerClass: 'text-center' },
      { field: 'workRecordedDate', headerName: this._translate.instant('workRecordedDate'), type: 'TimeFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, width: 150,
          minWidth: 150, maxWidth: 150, headerTooltip: '', headerClass: 'text-center', cellClass: 'text-center' },
      { field: 'workCriticality', headerName: this._translate.instant('workCriticalityLabel'), cellRenderer: 'criticalityRenderer',
          sortable: true, width: 100,  valueGetter: 'data.workCriticality',
          minWidth: 100, maxWidth: 100, headerTooltip: '', headerClass: 'text-center' },
      { field: 'workStartDate', headerName: this._translate.instant('workStartDate'), type: 'TimeFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, width: 150,
          minWidth: 150, maxWidth: 150, headerTooltip: '', headerClass: 'text-center', cellClass: 'text-center' },
      { field: 'workEndDate', headerName: this._translate.instant('workEndDate'), type: 'TimeFormat',
          cellRendererParams: { array: [this.timeFormat]},
          sortable: true, filter: true, width: 150,
          minWidth: 150, maxWidth: 150, headerTooltip: '', headerClass: 'text-center', cellClass: 'text-center' },           

      { field: 'allAvailable', headerName: this._translate.instant('availableLabel'), cellRenderer: 'availableRenderer',
          sortable: true, width: 90, valueGetter: 'data.allAvailable',
          minWidth: 90, maxWidth: 90, headerTooltip: '', headerClass: 'text-center' },
      { field: '', headerName: this._translate.instant('Action'),
          // template: this.getActions(), 
          cellRenderer: (params: any) => {
            const data = params.data;
            return this.getActions(data);
          },
          width: 200, minWidth: 200, maxWidth: 200,  headerTooltip: '', headerClass: 'text-center'
      },
    ];
    /*
    https://www.oodlestechnologies.com/blogs/how-to-do-styling-of-your-ag-grid-rows/
        Row Style

        For your rows if you want to use css styles then you can use:

        rowStyle: This property is used to set css style for all rows, provide css style directly to this property.
        getRowStyle: This is a callback which helps you to set style for specific rows.
        

        gridOptions.rowStyle = {background: 'red'};

        gridOptions.getRowStyle = function(params) {
            if (params.node.rowIndex % 2 === 0) {
                return { background: 'blue' }
            }
        }
        Row Class

        If you want to add css class for grid rows then use these properties:

        rowClass: Use this property to assign a class to each row. You can provide a single class as string or array of classes.
        getRowClass: This is a callback which helps you to set css class for specific rows.
        gridOptions.rowClass = 'green-class';

        gridOptions.getRowClass = function(params) {
            if (params.node.rowIndex % 2 === 0) {
                return 'shaded-effect';
            }
        }
        Row Class Rules

        For applying rules for certain class use gridOptions.rowClassRules. 
        You can provide either javascript object to this property which contains classes as keys and expression as values, if expression gets true the the class gets applied.

        When you use function for this then the params will have the attributes: data, rowIndex, node, context and api.

        gridOptions.rowClassRules: {
          // apply green to 2008
          'green-outer': function(params) { return params.data.year === 2008},

          // apply amber 2004
          'amber-outer': function(params) { return params.data.year === 2004},

          // apply red to 2000
          'red-outer': function(params) { return params.data.year === 2000}
        }

    */
    this.gridOptions = {
      columnDefs: this.getColDef(),
      enableFilter: true,
      rowDeselection: true,
      enableColResize: true,
      rowSelection: 'single',
      // navigateToNextCell: this.arrowNavigation, : non operationnel
      columnTypes: {
        'DateFormat': {cellRenderer: GridTools.dateRenderer_unixM},
        'TimeFormat': {cellRenderer: GridTools.dateRenderer_unixM},
        },
    };
    this.gridOptions.getRowStyle = function(params: any) {
      const data = params.data;
      if ((!data) || (data === 'undefined')) {
        return;
      }
      if (data.workCriticality === 'immobilized') {
        // https://www.rapidtables.com/web/color/green-color.html
        return { background: '#9ACD32!important' };
      }

    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };

    this.autoGroupColumnDef = {
      filterValueGetter: function(params: any) {
        const colGettingGrouped = params.colDef.showRowGroup;
        const valueForOtherCol = params.api.getValue(colGettingGrouped, params.node);
        return valueForOtherCol;
      }
    };
    this.loadingSubject.next(true);
    this.getWorksList();
    
    if (this._userService.getUserLogged() !== undefined) {
      this.userLangCur = this._userService.getUserLogged().userlang;
    }
    this.localeText = this._translate.getAgGridLabels();
  
  }
  onChangeStatutSelect(event: any) {
    this.statutSelected = event;
    if (event === 'ALL') {
      this.dataSource.data = this.filterStatutSearch('');
    } else  {
      const searchCur = this._translate.instant(event);
      this.dataSource.data = this.filterStatutSearch(searchCur);
    }
  }
  filterStatutSearch(value: string): any {
    const filter = value.toLowerCase();
    return this.cacheDatatemp.filter(rawCur => rawCur.workStatutLabel.toLowerCase().includes(filter));
  }
  onFilterTextBoxChanged(event: any) {
    const valueFilter = document.getElementById('filter-text-box')['value'];
    this.gridApi.setQuickFilter(valueFilter);

  }


  refresh() {
    // this.getWorksList();
    this.loadingIndicator = true;
    this.buildList();
  }
  
  getColDef() {
    return this.displayedColumns;
  }

  getActions(data: any) {
    /*let  rowData: any; 
     this.gridOptions.getRowStyle = function(params: any) {
      rowData = params.data;
    }; */
    let actions: string;
    
    actions = '<button type="button" class="btn btn-default mat-icon-button " data-action-type="edit" data-toggle="tooltip" data-placement="left" title="' +
                  this._translate.instant('edit tooltip') + '" style=" margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="edit" class="fa fa-edit" style="font-size: 20px"></span></button>' ;

    actions = actions + '<button type="button" class="btn btn-default mat-icon-button " data-action-type="changeStatut" data-toggle="tooltip" data-placement="left" title="' +
                  this._translate.instant('statut tooltip') + '" style=" margin-left: 5px;margin-right: 5px;" >' +
                  '<span data-action-type="changeStatut" class="fa fa-cog" style="font-size: 20px"></span></button>' ;

    return actions;
  }
  
  getWorksList() {
    this.selected = [];
    
    if (this._userService.getUserLogged() === undefined ) { return; }
    this.dataSource.data = [];
    this._wksCentralService.getWorksList(this._userService.getUserLogged().entity, this._userService.getUserLogged().userlang)
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // this.dataSource.data = data.body;
        const dataList = this.affectData(data.body);
        this.dataSource.data = dataList;
        
        this.extractColumnsName(this.dataSource.data);
        this.settingLabels(this.dataSource.data);
        this.cacheDatatemp = [...this.dataSource.data];
        this.loadingSubject.next(false);
        this.loadingIndicator = false;

      },
      err => {
        // console.log(err);
        if (this.optionsArg === 'equipmentSelect')  {
          return;
        }
        if ((err.status === 500) || (err.status === 404)) {
          let titleBox: string;
          let messageBox: string;
          titleBox = 'searching works';
          messageBox = 'no work found';
          titleBox = this._translate.getTranslate(titleBox);
          messageBox = this._translate.getTranslate(messageBox);
          this.displayMessageBox(titleBox, messageBox, 'ERROR', 'alertWks', 'readingWorks', null );
        }
      }
    );
  }
  affectData(dataArray: WksWorksModel[]): WksWorksModel[] {
    for (const itemCur of dataArray)  {
      if (itemCur.wksCalendar !== undefined && itemCur.wksCalendar !== null) {
        itemCur.workStartDate = moment(itemCur.wksCalendar.calStart).format(environment.fmtDateTimeTZ);
        itemCur.workEndDate = moment(itemCur.wksCalendar.calEnd).format(environment.fmtDateTimeTZ);
      }
      const dateRecorded = (itemCur.workRecordedDate ?  itemCur.workRecordedDate : itemCur.stdCreation);
      itemCur.workRecordedDate = moment(dateRecorded).format(environment.fmtDateTimeTZ);
    }
    dataArray.sort((obj1: WksWorksModel, obj2: WksWorksModel) => {
      return obj1.stdCreation > obj2.stdCreation ? -1 : 1;
    });
    return dataArray;
  }
  displayMessageBox(_titleBox: string, _messageBox: string, _messageType: string, _typeDialog: string, _actionCur: string, dataArg: any) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: _titleBox,
      typeDialog: _typeDialog,
      panelClass: 'stdTheme',
      contentMessage: _messageBox,
      data1: '',
      data2: '',
      messageType: _messageType

      };

    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        if (( data === 'okAction') && _actionCur === 'confirmChangeStatut') {
          const rowNode = this.gridApi.getRowNode(dataArg.iRow);
          const workStatutLabel = this._translate.instant(dataArg.workStatut);
          rowNode.setDataValue('workStatutLabel', workStatutLabel);
          this.workToUpdate.workStatut = dataArg.workStatut;
          this.workToUpdate.statutChange = true;
          const wksStatut = this._wksCentralService.getWorkStatut(this.workToUpdate.workStatut);
          this.workToUpdate.statutCommand = wksStatut.command;
          this.registerWork();
        }

        if (( data === 'okAction') && _actionCur === 'readingWorks') {
          this.loadingSubject.next(false);
          this.loadingIndicator = false;
        }
      });

  }
  openChangeStatutBox(row: WksWorksModel, _iRow: number) {

    const dialogConfig = new MatDialogConfig();
    this.workToUpdate = row;
    dialogConfig.height = '300px';
    dialogConfig.width = '500px';
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this._translate.getTranslate('changeWorkStatut'),
      typeDialog: '',
      panelClass: 'stdTheme',
      contentMessage: this.workToUpdate.workRef + ' / ' + this.workToUpdate.workCustomerName + ' / ' + this.workToUpdate.workMainEquipName,
      data1: this.workToUpdate,
      data2: '',
      messageType: ''

      };

    const dialogRef = this._dialog.open(WorksChangeStatutComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        // console.log('Dialog output:', data);
        if ( data.action === 'okAction' &&  data.workStatut !== this.workToUpdate.workStatut )  {
            const dataArg =  {
              iRow: _iRow,
              workStatut: data.workStatut
            };
            const titleBox = this._translate.getTranslate('confirmChangeStatut');
            const messageBox = this._translate.instant('changeWorkStatutLabel', [
              this._translate.getTranslate(this.workToUpdate.workStatut), 
              this._translate.getTranslate(data.workStatut)]);
            this.displayMessageBox(titleBox, messageBox, 'ERROR', 'confirmAction', 'confirmChangeStatut', dataArg );
        }
        if ( data.action === 'cancelAction')  {
       
        }
      });

  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.autoSizeAll(false);
    // params.api.sizeColumnsToFit();
  }
  public onRowClicked(e: any) {
    if (e.event.target !== undefined) {
      const selectedRow = this.gridApi.getSelectedRows();
        const data = e.data;
        if ((!data) || (data === 'undefined')) { return; }
        const actionGrid = e.event.target.getAttribute('data-action-type');

        switch (actionGrid) {
            case 'edit': {
                this.editWork(data, e.rowIndex);
                break;
            }
            /*
            // JLG - deprecated - 2023-03-02
            case 'quotation': {
              this.quotation(data, e.rowIndex);
              break;
            }
            */
            // changeStatut
            case 'changeStatut': {
              this.changeStatut(data, e.rowIndex);
              break;
            }
            /*
            case 'trash': {
                this.deleteWork(data);
                break;
            }
            */
        }
    }
  }
  onSelectionChanged() {
    const selectedRow = this.gridApi.getSelectedRows();
    // console.log('Row selected : ' + selectedRow );

  }
  extractColumnsName(_dataArray: Array<any>) {
    if (!_dataArray[0]) {
      return ;
    }
    // init update and save mark
    this.doUpdateWork = [...Array(_dataArray.length)].map(x => false);
    this.dataColumnsName = Object.keys(_dataArray[0]);
    this.dataColumns = CommonMethods.optionsSelected(this._translate, this.dataColumnsDef,
                                        this.dataColumnsName, this.displayedColumns, false);
    // console.log('Columns current : ', this.dataColumns);
  }
  settingLabels(_dataArray: Array<any>) {
    if (!_dataArray[0]) {
      return;
    }
    for (const rawCur of _dataArray) {
      rawCur.workCriticalityLabel = this._translate.instant(rawCur.workCriticality);
      rawCur.workStatutLabel = this._translate.instant(rawCur.workStatut);
    }
  }
  dataOutWork(workDataReturn: any) {
    this.workToUpdate = workDataReturn.wksWorksCard;
    const rowNode = this.gridApi.getRowNode(workDataReturn.iRow);
    rowNode.data = this.workToUpdate;
    rowNode.setDataValue('allAvailable', this.workToUpdate.allAvailable);
    rowNode.setDataValue('workObject', this.workToUpdate.workObject);
    rowNode.setDataValue('workStatutLabel', this._translate.instant(this.workToUpdate.workStatut));
    rowNode.setDataValue('workCriticality', this.workToUpdate.workCriticality);
    if (this.workToUpdate.wksCalendar !== undefined && this.workToUpdate.wksCalendar !== null) {
      rowNode.setDataValue('workStartDate', moment(this.workToUpdate.wksCalendar.calStart).format(environment.fmtDateTimeTZ));
      rowNode.setDataValue('workEndDate',  moment(this.workToUpdate.wksCalendar.calEnd).format(environment.fmtDateTimeTZ));
    }

    this.dataSource.data[workDataReturn.iRow] = this.workToUpdate;
    // const rowNodes = [rowNode];
    // this.gridApi.redrawRows({ rowNodes: rowNode });
   /* const params = {
        force: true,
        suppressFlash: true,
        rowNodes: rowNodes,
      };*/
    // this.gridApi.refreshCells(params);
    // console.log(rowNode);
    // this.dataSource.data[workDataReturn.iRow] = this.workToUpdate;
  }

  toggleSidenav(_arg: string) {

   
    if (_arg === 'backdrop') {
      return;
    }
    this.displayWorkCard = false;
    if (this.displayType === 'newsearch') {
      return;
    }

    if (_arg === 'displayWork') {
      this.displayWorkCard = true;
    }
    this.sidenavCollapsed = !this.sidenavCollapsed;
    if (this.sidenavCollapsed) {
      try {
        this.sidenav.close();
      } catch (error) {
      } finally { }
    } else {
      try {
        this.sidenav.open();
      } catch (error) {
      } finally { }
    }
  }
  /*
  editWorkAgGrid() {

    const selectedRow = this.gridApi.getSelectedRows();
    this.workToUpdate = selectedRow;
    this.actionType = 'update';
    this.toggleSidenav('registerWork');
  }
  */
 autoSizeAll(skipHeader: boolean) {
  const  allColumnIds = [];
  this.gridColumnApi.getAllColumns().forEach(function (column: any) {
    allColumnIds.push(column.colId);
  });
  this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  }
  editWork(row: WksWorksModel, _iRow: number) {
    this.workToUpdate = row;
    this.dataInWork = {
      iRow: _iRow,
      work: this.workToUpdate
    };
    this.actionType = 'updateWork';
    // this.openModalWork(_iRow);
    this.toggleSidenav('displayWork');
  }
  // changeStatut
  changeStatut(row: WksWorksModel, _iRow: number) {
    this.openChangeStatutBox(row, _iRow);
  }
  /*
  // JLG - deprecated - 2023-03-02  
  quotation(row: WksWorksModel, _iRow: number) {
    this.workToUpdate = row;
    if (this.workToUpdate.workStatut ===  'completed') {
      this.actionType = 'quotation';
      this.generateQuotation(row, _iRow);
    } else {
      for (const statut of this.workStatuts) {
        if (statut.label !== 'completed') {
          if (statut.label === this.workToUpdate.workStatut)  {
            this.errorMessage('quotationAction', this._translate.getTranslate(statut.tooltip));
            break;
          }
        }
      }
    }
  }
  */
  addWork() {
  
    this.actionType = 'addWork';
    this.workToUpdate = undefined;
    this.dataInWork = {
      iRow: -1,
      work: this.workToUpdate
    };
    // this.openModalWork(-1);
    this.toggleSidenav('displayWork');
  
  }
  translateLabels() {

    if (this.displayedColumns === undefined) {
      return;
    }
    const nbCol = this.displayedColumns.length;
    const localColumns = this.displayedColumns;
    this.displayedColumns = [];
    for (let i = 0; i < nbCol; i++) {
      const lTmp = localColumns[i];
  
      this.displayedColumns.push({
        'prop': lTmp.prop,
        'name': this._translate.getTranslate(lTmp.prop),
        'isDate': false
      });
    }
  }
  registerWork() {
    this.fillTracking();
    this._wksCentralService.saveWksWorks(this.workToUpdate, 'update')
    .subscribe(
      paramWorksRatesReturn => {
        // this.workRateToUpdate = paramWorksRatesReturn.body;
      },
      () => {
        // this.errorMessage('register');
      }
    );
  }
  fillTracking()  {
    this.workToUpdate = this._wksWorksService.fillTracking(this.workToUpdate, this._userService.getUserLogged().username, 
                                    this.workToUpdate.workStatut, this.workToUpdate.workComment);
  }
  // Modal
  closeModal() {

    if (this.modalRef !== undefined) {
      this.modalRef.hide();
    } 
  }
  
  setModalClass() {
    this.valueWidth = !this.valueWidth;
    const modalWidth = this.valueWidth ? 'modal-lg' : 'modal-sm';
    this.modalRef.setClass(modalWidth);
  }


  errorMessage(_actionCur: string, _sMessage: string) {
    const dialogConfig = new MatDialogConfig();
    let titleBox: string;
    titleBox = 'quote';
    let messageBox: string;
    if (_actionCur === '208') {
      messageBox = 'No more data';
    } 

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if (_sMessage === null ) {
      dialogConfig.data = {
        id: 1,
        title: this._translate.getTranslate(titleBox),
        typeDialog: 'alertWks',
        panelClass: 'stdTheme',
        contentMessage: this._translate.getTranslate(messageBox),
        data1: '',
        data2: '',
        messageType: 'ERROR'

        };
    } else {
      dialogConfig.data = {
        id: 1,
        title: this._translate.getTranslate(titleBox),
        typeDialog: 'alertWks',
        panelClass: 'stdTheme',
        contentMessage: _sMessage,
        data1: '',
        data2: '',
        messageType: 'ERROR'

        };
    }
  //   this._dialog.open(ModalCommonComponent, dialogConfig);
    this._globalEventsManagerService.synchroEvents
      .pipe(takeUntil(this.onDestroy))
      .subscribe( event => {
        if (event === 'cancelModal') {
          console.log('fermeture');
        } else if (event) {
          const typeEvent = typeof event;
          // const eventString = JSON.stringify(event).toString();
          const eventString = String(event);
          console.log(typeEvent);
        }
        // this.router.navigate(['/jobHome']);
      });
    const dialogRef = this._dialog.open(ModalCommonComponent, dialogConfig);

    dialogRef.afterClosed()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(
      data => {
        if (data !== undefined)  {
          console.log('Dialog output:', data);
        }
      });

  }
  // https://material.angular.io/components/snack-bar/overview
  // evolution : http://www.eduforbetterment.com/snackbar-model-in-angualr/
  // 2021-05 : https://stackblitz.com/edit/angular-material-snackbar-example
  // 2021-05 : https://stackblitz.com/edit/angular-snackbar
  openSnackBar(message: string, action: string) {
    let actionCur = action;
    if (action === 'No-Action') {
      actionCur = '';
    }
    this.snackBar.open(message, actionCur, {
      duration: 7000,
      panelClass: ['custom-style']
    });

  }
}
